import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <>
    
  <div className='footer bg-dark text-secondary ' id="hisfooter">
      <div className="container bg-dark text-secondary ">
  <footer className="py-5 bg-dark text-secondary ">
    <div className="row bg-dark text-secondary">


    <div className="col-6 col-md-2 mb-3 bg-dark text-secondary">
    <img src="/logo.png" className='footer-img' />
        <ul className="nav flex-column bg-dark text-secondary">
          <li className="nav-item6 mb-2 text-capitalize"><Link to="/about" className="nav-link p-0 text-secondary ">Bookindya is the online bookstore for
buying new books and used/old
books at unbeatable prices with lowest
shipping charges across India. We
personally assess each and every book
to ensure you get the best quality and
value for money.</Link></li>

        </ul>
      </div>
      <div className="col-6 col-md-2 mb-3 bg-dark text-secondary">
        <h5>About Us</h5>
        <ul className="nav flex-column bg-dark text-secondary">
          <li className="nav-item mb-2 text-capitalize"><Link to="/about" className="nav-link p-0 text-secondary">About</Link></li>
          <li className="nav-item mb-2"><Link to="/about" className="nav-link p-0 text-secondary">Our Stories</Link></li>
          <li className="nav-item mb-2"><Link to="/about" className="nav-link p-0 text-secondary">Our Team Memeber</Link></li>
          <li className="nav-item mb-2"><Link to="/about" className="nav-link p-0 text-secondary">Frequently Asked Questions</Link></li>
          <li className="nav-item mb-2"><Link to="/contactus" className="nav-link p-0 text-secondary">Contact Us</Link></li>
          
        </ul>
      </div>

      <div className="col-6 col-md-2 mb-3">
        <h5>Polices</h5>
        <ul className="nav flex-column ">
          <li className="nav-item mb-2"><Link to="/terms-condition" className="nav-link p-0 text-secondary">Terms & Conditions</Link></li>
          <li className="nav-item mb-2"><Link to="/shipping" className="nav-link p-0 text-secondary">SHIPPING POLICY </Link></li>
          <li className="nav-item mb-2"><Link to="/delivery" className="nav-link p-0 text-secondary">DELIVERY POLICY</Link></li>
          <li className="nav-item mb-2"><Link to="/cancellation" className="nav-link p-0 text-secondary">CANCELLATION POLICY</Link></li>
          <li className="nav-item mb-2"><Link to="/return" className="nav-link p-0 text-secondary">RETURN POLICY</Link></li>
          <li className="nav-item mb-2"><Link to="/replacement" className="nav-link p-0 text-secondary">REPLACEMENT POLICY</Link></li>
          <li className="nav-item mb-2"><Link to="/refund" className="nav-link p-0 text-secondary">REFUND POLICY</Link></li>
          <li className="nav-item mb-2"><Link to="/privacy" className="nav-link p-0 text-secondary">PRIVACY POLICY</Link></li>

        </ul>
      </div>

      <div className="col-6 col-md-2 mb-3">
        <h5>follow us</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="https://www.youtube.com/@Booksindya" className="nav-link p-0 text-secondary">youtube</Link></li>
          <li className="nav-item mb-2"><Link to="https://www.instagram.com/booksindya.in?igsh=YnI3MG1zZ29jN2sz" className="nav-link p-0 text-secondary">instagram</Link></li>
          <li className="nav-item mb-2"><Link to="https://www.facebook.com/profile.php?id=61553364841273&mibextid=ZbWKwL" className="nav-link p-0 text-secondary">facebook</Link></li>
          <li className="nav-item mb-2"><Link to="https://wa.me/917003810616?text=Hello Booksindya" className="nav-link p-0 text-secondary">whatsapp</Link></li>
          <li className="nav-item mb-2"><Link to="https://x.com/booksindya2024?t=A5QAyVfRyWScNYsiCdQsWg&s=09" className="nav-link p-0 text-secondary">twitter</Link></li>
          <li className="nav-item mb-2"><Link to="https://www.linkedin.com/in/booksindya-mart-958b92314?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="nav-link p-0 text-secondary">linkedin</Link></li>
          <li className="nav-item mb-2"><Link to="https://telegram.org/dl" className="nav-link p-0 text-secondary">telegram</Link></li>
          
        </ul>
      </div>


      <div className="col-6 col-md-2 mb-3">
        <h5>Address</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><Link to="https://www.google.com/maps/place/Mission+of+Knowledge/@23.7945351,86.4448558,17z/data=!3m1!4b1!4m6!3m5!1s0x39f6bc933993705d:0xd952be64d34bd726!8m2!3d23.7945351!4d86.4448558!16s%2Fg%2F11c5s5yqrb?entry=ttu" className="nav-link p-0 text-secondary">
          REG.OFFICE: <br />
CHIRAGORA, SAMSHAN
ROAD,BEHIND-MISSION
OF KNOWLEDGE SCHOOL
HIRAPUR , DHANBAD,
JHARKHAND, INDIA
PINCODE-826001
<br />
<br />
MOBILE No(+91) 7003810616
<br /><br />
TIMING: 10 AM to 6PM
(Monday to Saturday)
          </Link>

</li>
          
          
        </ul>
      </div>

      <div className="col-md-5 offset-md-1 mb-3">
 
      </div>
    </div>

    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top ">
      <p>&copy; 2023 Lokesh.Dev, Developer. All rights reserved. Contact <Link className="text-decoration-none text-light" to="https://www.lokeshdevcoder.com">www.lokeshdevcoder.com</Link></p>
      <ul className="list-unstyled d-flex">
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://www.instagram.com/booksindya.in?igsh=YnI3MG1zZ29jN2sz"><i className="fa-brands fa-instagram"></i></Link></li>
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://www.facebook.com/profile.php?id=61553364841273&mibextid=ZbWKwL"><i className="fa-brands fa-facebook"></i></Link></li>
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://x.com/booksindya2024?t=A5QAyVfRyWScNYsiCdQsWg&s=09"><i className="fa-brands fa-twitter"></i></Link></li>
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://www.youtube.com/@Booksindya"><i className="fa-brands fa-youtube"></i></Link></li>
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://www.linkedin.com/in/booksindya-mart-958b92314?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fa-brands fa-linkedin"></i></Link></li>
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://wa.me/917003810616?text=Hello Booksindya"><i className="fa-brands fa-whatsapp"></i></Link></li>
        <li className="ms-3"><Link className="link-body-emphasis text-light" to="https://telegram.org/dl"><i className="fa-brands fa-telegram"></i></Link></li>
      </ul>
    </div>
  </footer>
</div>
    </div>
    </>
  )
}

export default Footer