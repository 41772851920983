
import './App.css';
import DisableDevtool from 'disable-devtool';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Forget from './Components/Minorcomponents/Forget';
import Login from './Components/Minorcomponents/Login';
import Signin from './Components/Minorcomponents/Signin';

import Navbar from './Components/Navbar';

import {BrowserRouter,Routes,Route} from 'react-router-dom';

import Products from "./Components/Products"
import Itempage from './Components/Itempage';
import Contact from './Components/Minorcomponents/Contact'
import Searchstate from './Components/context/Searchstate';
import Search from './Components/Minorcomponents/Search';
import Error from './Components/Minorcomponents/Error';
import Cart from './Components/Minorcomponents/Cart';
import About from './Components/Minorcomponents/About';
import Finalorder from './Components/Minorcomponents/Finalorder';
import Billing from './Components/Minorcomponents/Billing';
import Myorder from './Components/Minorcomponents/Myorder';
import Wishlist from './Components/Minorcomponents/Wishlist';
import Orderhistory from './Components/Minorcomponents/Orderhistory';
import { useEffect } from 'react';
import Reqform from './Components/Minorcomponents/Reqform';
import Mypolicy from './Components/Mypolicy';
import Newarrivalpage from './Components/Newarrivalpage';
import Toppublisher from './Components/Toppublisher';
import Bestseller from './Components/Bestseller';
import Combo from './Components/Combo';
import Used from './Components/Used';
import TermCond from './Components/TermCond';
import Bhagwadgita from './Components/Bhagwadgita';
import Narendramodi from './Components/Narendramodi';
import SCC from './Components/SCC';
import RRB from './Components/RRB';
import Shipping from './Components/Shipping';
import Delivery from './Components/Delivery';
import Cancellation from './Components/Cancellation';
import Return from './Components/Return';
import Replacement from './Components/Replacement';
import Refund from './Components/Refund';
import Privacy from './Components/Privacy';
import Freebookpage from './Components/Freebookpage';
import Publication from './Components/Publication';

function App() {
  useEffect(()=>{
    console.clear()
   
      //  DisableDevtool();
  },[])
  
  
  return (
 <>
 
<Searchstate>

<BrowserRouter>
<Navbar/>

<Routes>
  <Route exact path='/' element={<Home/>}></Route>
  <Route exact path='/signup' element={<Signin/>}></Route>
    <Route exact path='/login' element={<Login/>}></Route>
    <Route exact path='/forget' element={<Forget/>}></Route>
    <Route exact path='/product/:id' element={<Products/>}></Route>
    <Route exact path='/productpage/:id' element={<Itempage/>}></Route>
    <Route exact path='/contactus' element={<Contact/>}></Route>
<Route exact path='/search' element={<Search/>}></Route>
<Route exact path='*' element={<Error/>}></Route>
<Route exact path='/cart' element={<Cart/>}></Route>
<Route exact path='/about' element={<About/>}></Route>
<Route exact path='/createorder' element={<Finalorder/>}></Route>
<Route exact path='/billing' element={<Billing/>}></Route>
<Route exact path='/myorder' element={<Myorder/>}></Route>
<Route exact path='/wishlist' element={<Wishlist/>}></Route>
<Route exact path='/orderhistory' element={<Orderhistory/>}></Route>
<Route exact path='/reqbook' element={<Reqform/>}></Route>
<Route exact path='/newarrival' element={<Newarrivalpage/>}></Route>
<Route exact path='/toppublisher' element={<Toppublisher/>}></Route>
<Route exact path='/bestseller' element={<Bestseller/>}></Route>
<Route exact path='/used&old' element={<Used/>}></Route>
<Route exact path='/combo&offer' element={<Combo/>}></Route>
<Route exact path='/terms-condition' element={<TermCond/>}></Route>
<Route exact path='/bhagwad-gita' element={<Bhagwadgita/>}></Route>
<Route exact path='/narendra-modi' element={<Narendramodi/>}></Route>
<Route exact path='/SCC' element={<SCC/>}></Route>
<Route exact path='/RRB' element={<RRB/>}></Route>
<Route exact path='/shipping' element={<Shipping/>}></Route>
<Route exact path='/delivery' element={<Delivery/>}></Route>
<Route exact path='/cancellation' element={<Cancellation/>}></Route>
<Route exact path='/return' element={<Return/>}></Route>
<Route exact path='/replacement' element={<Replacement/>}></Route>
<Route exact path='/refund' element={<Refund/>}></Route>
<Route exact path='/privacy' element={<Privacy/>}></Route>
<Route exact path='/ourpublisher/:id' element={<Publication/>}></Route>
{/* <Route exact path='/freebook' element={<Freebookpage/>}></Route> */}
</Routes>
<Footer/>

</BrowserRouter>
</Searchstate>
  
 </>
  );
}

export default App;
