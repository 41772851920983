import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useRazorpay from "react-razorpay";
import { useContext } from 'react'
import Context from '../context/Searchcontext'
const Billing = () => {
  const context=useContext(Context)
  const {mycode, setmycode,change,discountprice, setdiscountprice}=context
  const [alert, setalert] = useState(null)
  const [Razorpay] = useRazorpay();
  const [items, setitems] = useState({sum:"",books:[]})
  const [adddetail, setadddetail] = useState({state:"",city:"",pincode:""})
  const [address, setaddress] = useState({line1:"",line2:"",landmark:""})
  const navigate=useNavigate()
const location=useLocation()  
const [order, setorder] = useState(null)
const [key, setkey] = useState(null)

useEffect(()=>{
  document.documentElement.scrollTo({
    top:0,
    behavior:"smooth"
  })
},[location])
useEffect(()=>{
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/useritem`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "token":localStorage.getItem("token")
        }
      }).then((res)=>res.json()).then((ele)=>{
        if(ele.length==0)
        {
          
          navigate("/")
        }
      })
if(!localStorage.getItem("token"))
{
navigate("/login")
}


fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/authentication`,{
  method:"POST",
}).then((res)=>res.json()).then((ele)=>{
 setkey(ele.key)
  
})

    },[])

const handlechange=async(e)=>{

fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getpincode`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json"
  },body:JSON.stringify({pincode:e.target.value})
}).then((res)=>res.json()).then((ele)=>{setadddetail({state:ele.state,city:ele.city,pincode:ele.pincode})})
}
  
const handlechange2=(e)=>{
  setadddetail({...setadddetail,[e.target.name]:e.target.value})
 
}

const handlechange3=(e)=>{
  setaddress({...address,[e.target.name]:e.target.value})
 
}

const handlesubmit=async(e)=>{
e.preventDefault()
let x=document.getElementById("line1")
let y=document.getElementById("line2")
let z=document.getElementById("landmark")

if(x.value.length<6 || y.value.length<6 || z.value.length < 3)
{
setalert({message:"Invalid Credentials",color:"danger"})
  return
}
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/totalprice`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json",
    "token":localStorage.getItem("token")
  }
}).then((res)=>res.json()).then((ele)=>{
  if(ele.success)
  {
    if(ele.sum>=200){

      
      setitems({sum:ele.sum-discountprice,books:ele.books})
    }
    else{

      setitems({sum:ele.sum,books:ele.books})
    }
 
    fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/createorder`,{
      method:"POST",
      headers:{
          "Content-Type":"application/json"
      },body:JSON.stringify({amount:ele.sum+ele.charge-discountprice})
  }).then((res)=>res.json()).then((ele)=>{
      setorder(ele)
      
  })

  }
})
}

useEffect(() => {
  if(!localStorage.getItem("token"))
  {
    navigate("/login")
  }
}, [])
useEffect(()=>{
setTimeout(()=>{
setalert(null)
},2000)
},[alert])

useEffect(()=>{

 if(order)
{
  const options= {
    key: key,
    amount: order.amount,
    currency: "INR",
    name: "Booksindya",
    description: `${[...items.books]}`,
    order_id: order.id,
    "handler": function (response){
      console.log(adddetail.line1)
     fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/verifypayment`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
      },body:JSON.stringify({username:localStorage.getItem("name"),code:mycode,address_line1:address.line1,address_line2:address.line2,pincode:adddetail.pincode,state:adddetail.state,landmark:address.landmark,town:adddetail.city,razorpay_payment_id:response.razorpay_payment_id,razorpay_order_id:response.razorpay_order_id,razorpay_signature:response.razorpay_signature,order_id:order.id})
     }).then((res)=>res.json()).then((ele)=>{
      navigate("/myorder")
     })
  },
    redirect:false,
    prefill: {
      name: `${localStorage.getItem("name")}`,
      email: `${localStorage.getItem("email")}`,
      contact: `${localStorage.getItem("pno")}`,
      address:`${adddetail.line1+" "+adddetail.line2+" "+adddetail.landmark+" "+adddetail.city+" "+adddetail.state+" "+adddetail.pincode}`
    },
    notes: {
      address: "Venus Publication",
    },
    theme: {
      color: "#FF9843",
    },
  };

  const rzpay = new window.Razorpay(options);
  rzpay.open();
}
},[order])

  return (
  <>
  {alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong><i className="fa-regular fa-thumbs-down"></i></strong> {alert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}

 <main>
  <section className='d-flex justify-content-center my-5'>
<form onSubmit={handlesubmit}>
<div className="billing-container">
<h1>Checkout</h1>
<div>
  <label htmlFor="address line 1" className='fw-bolder'>Flat,House no.,Building,Company,Apartment</label>
  <input type="text" name="line1" minLength={6}  onChange={handlechange3} id="line1" />
</div>

<div>
  <label htmlFor="address line 2" className='fw-bolder'>Area,Street,Sector,Village</label>
  <input type="text" name="line2" minLength={6}  onChange={handlechange3} id="line2" />
</div>

<div>
  <label htmlFor="address line 2" className='fw-bolder'>Landmark</label>
  <input type="text" name="landmark" minLength={6} onChange={handlechange3} id="landmark" />
</div>
<div>
  <label htmlFor="Pincode" className='fw-bolder'>Pincode</label>
  <input type="tel" name="pincode" minLength={6} maxLength={6} id="pincode" onChange={handlechange} />
</div>

<div>
<label htmlFor="state" className='fw-bolder'>State</label>
  <input type="tel" readOnly name="state"  maxLength={40} value={adddetail.state} id="state" />
</div>

<div>
<label htmlFor="State" className='fw-bolder'>Town/City</label>
  <input type="tel" readOnly name="city" maxLength={40} value={adddetail.city} id="city" />
</div>



<button type='submit'  className="btn-warning btn-sm btn">Continue Payment</button>
</div>
</form>
  </section>
 </main>
  
  </>
  )
}

export default Billing