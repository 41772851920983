import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Orderitem2 from './Orderitem2'
import { useState } from 'react'

const Myorder = () => {
    const [alert, setalert] = useState(null)
    const location=useLocation()
    const navigate=useNavigate()
    const [data, setdata] = useState([])

    useEffect(()=>{
      document.documentElement.scrollTo({
        top:0,
        behavior:"smooth"
      })
      document.querySelector("header").style.display="block"
    },[location])

    useEffect(()=>{
if(!localStorage.getItem("token"))
{
navigate("/login")
}

    },[])

    useEffect(()=>{
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getmyorder`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
    }
}).then((res)=>res.json()).then((ele)=>{
   
    setdata(ele.order)})
    },[])
  return (
    <>
    {alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong><i className="fa-regular fa-thumbs-up"></i></strong> {alert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    <main>
        <section className='d-flex justify-content-center'>
            <div className="myorder-container mt-3">

 { data.map((ele)=>{
     return <Orderitem2  key={ele._id} ele={ele} />
    })}

{!data.length && <div className='d-flex flex-column align-items-center mt-3'>
<h1>No Order Found</h1>
<img id='itemnotfound' src="/itemnotfound.png" alt="itemnotfound" />
</div>
}
            </div>

        </section>
    </main>
    </>
  )
}

export default Myorder