import React from 'react'
import style from '../App.css'
const Bhagwadgita = () => {
  return (
    <div class="blog-mainContainer">
    <div class="blogmain-text-container">
        <h1>Srimad Bhagavad Gita</h1>
        <div class="blogmain-text">
            <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span>
                “Srimad Bhagavad Gita is a Dharmshastra not only for Hindu but for all human beings. Message of Gita
                is relevant for
                all Religions (Dharm) of the world and is not limited for any particular Religion”.The Bhagavad-gita
                is universally
                renowned as the jewel of India's spiritual wisdom.No other philosophical or religious work reveals,
                in such a lucid and
                profound way, the nature of consciousness, the self, the universe and the Supreme. Whether from the
                perspective of an
                ancient Yogi or a modern-day practitioner, The Bhagavad Gita speaks to anyone who ever feels as
                though their mind is a
                battlefield.
            </p>

            <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span>
                The Bhagavad Gita is one of the most prominent Hindu texts, forming a part of the Indian epic known
                as Mahabharata.
                This still stands as the longest poem ever written, with over 100,000 shlokas or over 200,000
                verses.It gives us a
                systematic overview of the territory [of life], shows various approaches to the summit with their
                benefits and pitfalls,
                offers recommendations, tells us what to pack and what to leave behind. More than either of the
                others (the Upanishads
                and the Dhammapada), it gives the sense of a personal guide. It asks and answers the questions that
                you or I might ask.
                Questions not about philosophy or mysticism, but about how to live life effectively in a world of
                challenge and change.
            </p>

            <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span>
                The Bhagavad Gita is set in a narrative framework of dialogue between the Pandava prince Arjuna and
                his charioteer
                guide Krishna, an avatar of Vishnu. At the start of the Kurukshetra War between the Pandavas and the
                Kauravas, Arjuna
                despairs thinking about the violence and death the war will cause in the battle against his kin and
                becomes emotionally
                preoccupied with a dilemma.Wondering if he should renounce the war, Arjuna seeks the counsel of
                Krishna, whose answers
                and discourse constitute the Bhagavad Gita. Krishna counsels Arjuna to "fulfil his Kshatriya
                (warrior) duty" for the
                upholding of dharma.The Krishna–Arjuna dialogue covers a broad range of spiritual topics, touching
                upon moral and
                ethical dilemmas, and philosophical issues that go far beyond the war that Arjuna faces.The setting
                of the text in a
                battlefield has been interpreted as an allegory for the struggles of human life.
            </p>

            <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span>
                The Bhagavad Gita has eighteen chapters, each chapter is called a yoga. Yoga is the existence of the
                individual
                consciousness attaining communion with the Ultimate Consciousness. Each chapter is a highly
                specialized yoga revealing
                the path of attaining realization of the Ultimate Truth. Gita is also a ‘song’ that raises the hope
                and possibility for
                human individual and collective well-being. So in everyone’s life also, the 18 chapters of the
                Bhagavad Gita
                happen.Although Bhagwad Gita is a subject which a person may not be able to understand completely in
                his entire life
                span, I will try to overview in short, Bhagwad Gita chapter by chapter, for the benefit of my
                readers:
            </p>

            <p>
                <span><b>Chapter 1: Lamenting the Consequence of War:</b></span>
                Chapter one introduces the scene, the setting, the circumstances and the characters involved determining the reasons for
                the Bhagavad-Gita's revelation. The scene is the sacred plain of Kuruksetra. The setting is a battlefield. The
                circumstances is war. The main characters are the Supreme Lord Krishna and Prince Arjuna, witnessed by four million
                soldiers led by their respective military commanders. After naming the principal warriors on both sides, Arjunas growing
                dejection is described due to the fear of losing friends and relatives in the course of the impending war and the
                subsequent sins attached to such actions.
            </p>

            <p>
                <span><b>Chapter 2: The Eternal Reality of the Soul’s Immortality:</b></span>
                This chapter is often deemed to be the summary of the entire Bhagwad Gita, this second chapter in your life
                predominantly deals with the immortal nature of the soul existing in all living entities and it has been described in
                great detail. Arjuna submits to Lord Krishna as His disciple, and Krishna begins His teachings to Arjuna by explaining
                the fundamental distinction between the temporary material body and the eternal spiritual soul. Lord Krishna explains
                the nature of selfless service to the Supreme and the characteristics of a self-realized person.
            </p>

            <p>
                <span><b>Chapter 3: The Eternal Duties of Human Beings:</b></span>
                Here Lord Krishna categorically and comprehensively explains how it is the duty of each and every member of society to
                carry out their functions and responsibilities in their respective stage of life according to the rules and regulations
                of the society in which one lives. Further the Lord explains why such duties must be performed, what benefit is gained
                by performing them, what harm is caused by not performing them. Plus what actions lead to bondage and what actions lead
                to salvation.
            </p>


            <p>
                <span><b>Chapter 4: Approaching the Ultimate Truth:</b></span>The fourth chapter tells you, now that you are acting you must also listen to knowledge. Lord Krishna explains how
                spiritual knowledge is received by disciples and the necessity of approaching a ‘guru’ a realized teacher. He also
                explains the paths of actions and knowledge as well as wisdom regarding the supreme knowledge which results in the
                culmination of the two paths. Every act can be an act of Prayer.
            </p>

            <p>
                <span><b>Chapter 5: Action and Renunciation:</b></span>In chapter five Lord Krishna delineates the concepts of action with detachment and renunciation in actions explaining
                that both are a means to the same goal. Here He explains how salvation is attained by the pursuance of these paths. Thus
                this chapter is entitled:
            </p>

            <p>
                <span><b>Chapter 6: Connect to the Higher Consciousness:</b></span>The sixth chapter is when you learn to meditate and connect to the Higher consciousness. Lord Krishna reveals ‘astanga’
                yoga and the process of practicing such yoga. Astanga Yoga controls the mind and the senses and focuses on Parmatma (
                the Supersoul). He explains in detail the difficulties of the mind and the procedures by which one may gain mastery of
                their mind through yoga which reveals the spiritual nature of a living entity.
            </p>

            <p>
                <span><b>Chapter 7: Knowledge of the Ultimate Truth:</b></span>In chapter seven Lord Krishna gives concrete knowledge of the absolute reality as well as the opulence of divinity. He
                describes His illusory energy in the material existence called Maya and declares how extremely difficult it is to
                surmount it. He also describes the four types of people attracted to divinity and the four types of people who are
                opposed to divinty. In conclusion He reveals that one in spiritual intelligence takes exclusive refuge of the Lord
                without reservation in devotional service.
            </p>

            <p>
                <span><b>Chapter 8:Attainment of Salvation:</b></span> In the eighth chapter Lord Krishna emphasizes the science of yoga. He also gives information on the creation of the
                material world as well as establishing a distinction between them and the spiritual world. Here He reveals that one
                attains whatever one remembers at the end of one’s life. Krishna emphasizes the utmost importance of the very last
                thought at the moment of death. It means that by remembering Lord Krishna in devotion throughout one’s life, and
                especially at the time of death, one can attain to His supreme abode, beyond the material world.
            </p>

            <p>
                <span><b>Chapter 9:The Most Confidential Knowledge:</b></span>In chapter nine Lord Krishna reveals that the sovereign science and the sovereign secret. He explains how the entire
                material existence is created, prevaded, maintained and annihilated by His external energy and all beings are coming and
                going under His supervision. The subjects matters covered subsequently are primarily concerned with devotional service
                and the Lord Himself declares that these subject matters are most confidential.
            </p>

            <p>
                <span><b>Chapter 10: The Infinite Glory of the Ultimate Truth:</b></span> Chapter ten reveals Lord Krishna's exalted position as the cause of all causes. Also specifying His special
                manifestations and opulences. Arjuna prays to the Lord to describe more of the opulences and the Lord describes those
                which are most prominent.
            </p>

            <p>
                <span><b>Chapter 11: The Vision of the Universal Form:</b></span> In chapter eleven Lord Krishna is beseeched by Arjuna to reveal His universal form showing all of existence . Thus this
                chapter is entitled:

            </p>
            <p>
                <span><b>Chapter 12: The Path of Devotion:</b></span>In chapter twelve Lord Krishna extols the glory of devotion to God. Along with this he explains the different forms of
                spiritual disciplines and discusses the qualities of the devotees who by performing their activities in this way become
                very dear to Him.
            </p>

            <p>
                <span><b>Chapter 13: The Individual and Ultimate Consciousness:</b></span> In chapter thirteen Lord Krishna reveals the distinct difference between the physical body and the immortal soul. He
                explains that the physical is transitory and perishable whereas the soul is immutable and eternal. The Lord also gives
                precise knowledge about the individual soul and the ultimate soul.
            </p>

            <p>
                <span><b>Chapter 14:The Three Qualities of Material Nature:</b></span> In chapter fourteen Lord Krishna reveals that all embodied souls are under the control of three modes, or qualities, of
                material nature: goodness, passion, and ignorance, which influence everything in the material existence. Here He clearly
                advises to relinquish oneself from ignorance and passion and adopt the path of pure goodness until acquiring the ability
                to transcend them.
            </p>

            <p>
                <span><b>Chapter 15: Realization of the Ultimate Truth:</b></span>In chapter fifteen Lord Krishna reveals the virtues, the glories and transcendental characteristics of God being
                omnipotenet, omniscient and omnipresent. Also He explains the pupose and value of knowing about God and the means by
                which He can be realized.
            </p>

            <p>
                <span><b>Chapter 16:The Divine and the Demoniac Natures Defined:</b></span>In chapter sixteen Lord Krishna describes explicitly, explaining separately and in detail the divine properties,
                conduct, and actions that are righteous in nature and conducive to divinity. Krishna says that those who possess
                demoniac qualities and who live whimsically, without following regulations of scripture, attain lower births and further
                material bondage. But those with divine qualities and regulated lives and abide by the spiritual authority, gradually
                gain spiritual perfection.
            </p>

            <p>
                <span><b>Chapter 17:The Three Divisions of Material Existence:</b></span>In chapter seventeen Lord Krishna classifies the three divisions of faith, revealing that it is these different
                qualities of faith in the Supreme that determine that character of living entities. These three types of faith determine
                one's consciousness in this world.
            </p>

            <p>
                <span><b>Chapter 18: Final Revelations of the Ultimate Truth:</b></span>In chapter eighteen Lord Krishna sums up the ultimate conclusion of Gita and describes the meaning of renunciation and
                attainment of salvation by the paths of karma. The Lord explains that while doing so, one must offer without reservation
                everything to God. What Krishna means by renouncing is that actions should not be driven by desire. Relinquishing, on
                the other hand, means not having attachment to the results of actions. He tells Arjuna that even acts done with good
                intentions, such as worshipping, should be done without attachment to outcomes. Lord Krishna says that the unconditional
                loving surrender to Him frees one from all sins, and enables one to return to Krishna’s eternal spiritual abode.
            </p>

            <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span> This blog has taken me long time to summarize the vast subject that ‘Bhagwad Gita’ is and I still feel it is too
                little.

            </p>


        </div>
    </div>
</div>

  )
}

export default Bhagwadgita