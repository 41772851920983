import React from 'react'

const Orderitem = (props) => {

  return (
 <>
 <div className="order-card mt-4">
   <div className="top-order">
    <b>#Order Id</b>
    <b>Items</b>
    <b>Quantity</b>
    <b>Item price</b>
    <b>Total price</b>
    <b>Status</b>
   </div>
   <div className="bottom-order">
   <div>

   <b>{props.ele.order_id}</b>
   </div>

   <div >
    {props.ele.order_detail.map((ele,i)=>{
      return <b key={i}><br />{ele}</b>
    })}
   </div>

   <div>
   {props.ele.quantity.map((ele,i)=>{
     return <b key={i}><br />{ele}</b>
    })}
   </div>

   <div >
   {props.ele.itemprice.map((ele,i)=>{
     return <b key={i}><br /><i className="fa-solid fa-indian-rupee-sign"></i>{ele.toFixed(2)}</b>
    })}
   </div>

   <div>
    <b><i className="fa-solid fa-indian-rupee-sign"></i>{parseFloat(props.ele.total_amount).toFixed(2)}</b>
   </div>
   <div>
    <b>{props.ele.status}</b>
   </div>
 </div>
    </div> 
 </>
  )
}

export default Orderitem