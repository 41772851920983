import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Privacy = () => {
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  return (
    <div class="term-condition"> 
<h1>Privacy Policy</h1>

<ul>
  <li>This document is a written agreement and an electronic record and valid and enforceable electronic agreement / contract under Information Technology Act, 2000 (as applicable in Republic of India) and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws. This electronic record is generated by a computer system and does not require any physical or digital signatures. Your usage of the Website shall be your deemed acceptance of this Privacy Policy and all the modifications and updates thereto. Your privacy is very important to us. We do not publish, sell or rent your personal information to third parties for their marketing purposes; which includes your acceptance of this Privacy Policy. </li>

<li>Please read this privacy policy to learn more about the ways in which we use and protect your personal information. IFYOU DO NOT AGREE PLEASE DO NOT USE OR ACCESS OUR WEBSITE. </li>
<li>This Privacy Policy covers the information; Booksindya collect from the user(s) , whether or not registered with (“User(s)” and/or “You”), Www.Booksindya.in (“Website” including any successor website) and includes the Services – (the term as defined in the Terms & Condition). This Privacy Policy should be read in conjunction and together with the Terms of Use of the Website available on  <a href="https://booksindya.in/terms-condition/">https://booksindya.in/terms-condition/</a> .</li>
<li>Nature of Information collected by us: During your registration or usage of the Website you may provide and/or we may access, capture or store various sensitive personal information, personal information or general information or data with respect to your activities or usage on the Website. </li>

<li> We may install cookies or other similar data collection software in your computer system for such purpose and you hereby consent to the same. Certain information or data which you will provide includes: 
<br/>  1. First Name and Last Name (used as a user name)
 <br/> 2. Email
  <br/>3. Password(s) associated with the above user identification (email id)
  <br/>4. Mobile Number
  <br/>5. Address</li>


  <li>By using Booksindya website, you are here by consent to our disclaimer(s) and agree to its terms & conditions. If you need any more information or have any questions in your mind about our sites Terms & conditions, Privacy Policy, Return Policy, Delivery Policy, feel free to contact us through E-mail <span>(booksindya2024@gmail.com)</span> or Helpline <span>(+91 7003810616)</span>.</li>
</ul>
  </div>
  )
}

export default Privacy