import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Return = () => {
    let location=useLocation()
    useEffect(()=>{
      document.documentElement.scrollTo({
        top:0,
        behavior:"smooth"
      })
    },[location])
  return (
<div className="policy2-container">

    

    <div className="section">

        
        <h2>RETURN POLICY</h2>
        <p>
            To return products that are damaged in transit or in case a wrong product is delivered, please contact customer support by WhatsApp on <span className="highlight">+917003810616</span> or mail us at <span className="highlight">Booksindya2024@gmail.com</span> within 3 working days of delivery. Any return requests later than that will not be entertained.
            You have 3 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. Your item must be in the original packaging. Your item needs to have the receipt or proof of purchase.
            No Problem, We accept returns in these cases within 3 days of the date of delivery. We do ask that you please understand that shipping fees will not be reimbursed.
            Once the return is received, the refund will be processed in 3 days and you can expect it in your same payment method within 7 days.
        </p>
        <p>
            Please send the return to:
            <br/>
            Kiran Sinha
            <br/>
            C/O Ravi Bhushan Srivastava
            <br/>
            Chiragora, Samshan Road,
            <br/>
            Behind Mission of Knowledge school, Hirapur,
            <br/>
            Dhanbad-826001.
            <br/>
            Mobile no: 7003810616
        </p>
        <p>
            “Booksindya” cannot provide any return for OLD/USED books purchased from our websites.
        </p>
        
    </div>

 
    </div>
  )
}

export default Return