import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import PhotoAlbum from "react-photo-album";

const About = () => {
const [doc, setdoc] = useState(500)
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/usercountdoc`,{
  method:"POST"
}).then((res)=>res.json()).then((ele)=>{

  setdoc(ele.count)})
  },[])
  const photos = [
    { src: "/images/image1.jpg", width: 800, height: 600 },
    { src: "/images/image3.jpg", width: 1600, height: 900 },
    { src: "/images/image4.jpg", width: 1600, height: 900 },
    { src: "/images/image5.jpg", width: 1600, height: 900 },
    { src: "/images/image6.jpg", width: 1600, height: 900 },
    { src: "/images/image7.jpg", width: 1600, height: 900 },
    { src: "/images/image8.jpg", width: 1600, height: 900 },
    { src: "/images/image9.jpg", width: 1600, height: 900 },
    { src: "/images/image10.jpg", width: 1600, height: 900 },
    { src: "/images/image11.jpg", width: 1600, height: 900 },
    { src: "/images/image12.jpg", width: 1600, height: 900 },
  ];
  return (
   <>

<main className='about-main'>
        <section id='active-user '>
<h4 className='d-flex justify-content-center'> <b> No. Of Active Users Are {doc+500} <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div> </b></h4>
        </section>
        <section id="story">
            <h1>Our Story</h1>
           
         <p>◈ Our Stories begin with a small publishing company name “Venus Publication” in 2016 from a rented house at Dhanbad, Where we published books for Technical students of  Bihar and Jharkhand.</p>
        <p>◈ In 2019, We decided to expand our publishing company toward non-technical students and we published Railway General Studies, General Science and General Mathematics types of books.</p>
        <p>◈ The content of the books is reliable and proofread by our experienced teachers. But we noticed that, our books were not becoming very popular between student and we were not able to sold even 200 books.</p>
        <p>◈ Then we decided that, we should find out the real problem and we saw that many students did not  even know about our publication books.</p>
       <p>◈ After knowing the real reason, we tried many options for connecting with students and selling our publication books but we failed. </p>
       <p>◈ After the failure, we decided to take a last chance and sell our publication books at top book selling website.</p>
       <p>◈ We create a seller account name “Booksindya” at top book selling website and we put it up for sale and nothing happened. We would sell a copy here, a copy there, and that would be it. We sold a few copies per month at the very most.</p>
       <p>◈ Three months later, we realized that we hadn’t checked his sales lately, so we jumped online and was pleased to discover that for the first time, we had sold TWELVE copies in just a week! That was his biggest sales to date!</p>
       <p>◈ The next month, we sold over a 500+ copies. The month after that, 1000 copies.</p>
       <p>◈ After few  month, Some of the offline book store also demand our publication books .</p>
       <p>◈ That’s was the turning point of our business, we know one thing very cleared that, If you published new books then it is very important to sell it online to connect and create demand amongst students.</p>
       <p>◈ But, one thing we always noticed as the customer point of view that lots of books is quite expensive as compared to offline market due to extra charges taken by the seller charged by E-commerce website company. </p>
       <p>◈ We were realized that, there is no such e-commerce website in India where new and old books of every age group are available at one platform with no hidden or extra charges.</p>
       <p>◈ And Finally In 2020, We had decided to change our vision from publishing company toward E-commerce website.</p>
       <p>◈ But unfortunately, we had stop Booksindya e-commerce project for 2 years due to COVID-19 pandemic and we struggled for 2 years.</p>
       <p>◈ In 2023, We again started our project “Booksindya” with new and dedicated team member. And this time as per our promise we have created an  e-commerce website for our loyal customers.</p>
       
       
        </section>

        <section id="mission">
            <h1>Our Team Member</h1>
            <p>◈ Booksindya is run by a group of people who are passionate about their work. Every member is bestowed with a responsibility that is expected to be done with punctuality and efficiency and above all honesty.</p>
        <p>◈ It is believed at Booksindya that a comfortable workspace is the key towards efficient functioning of any organization. All the workers are ensured a space that gives them encouragement and support for whatever work they do. Every employee is treated with equality and no kind of gender bias exists here as it is a place that crowns pure talent and hard work.</p>
        <p>◈ Every employee of Booksindya is challenged with situations that eventually helps them re-discover their working potential and grow linearly in their stream of work. The challenges thrown are like the pressure that carves a diamond out of coal. And with time the members of Booksindya have proved themselves to be the metaphor of diamonds. The innovative, strategic, and creative ideas of the members are recognized and applauded by all because Booksindya believes that the growth of any workplace lies in the unity of the workers.</p>
        </section>

        <section id="sets-apart">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
            <h1>1.How good are the books ?</h1>
            <p>All products sold on Booksindya checked for quality, though they are not brand new every possible measure have been taken to make sure they are readable and of desirable quality. We ensure to provide best quality books to our buyers. Books listed on our website are in good condition, most of them can be even added to your collection.</p>


      <h1>2. Are these books genuine?</h1>
      <p>Yes, we do not believe in piracy. All books sold by us have been procured by legitimate vendor against legitamate billing. Worry about so much you haven't read, leave the rest to us.</p>

      <h1>3. How can I confirm if my order has been accepted?</h1>
      <p>You will receive an email/Message from us confirming your order details which will contain an Order ID (for ex. BW123456), the list of product(s) ordered, and the expected date of delivery. <br /> You will be sent additional communication before your order is shipped. This mail will contain the Order ID along with the tracking digits which you can use to see where your shipment is at present.</p> 

      <h1>4. When I buy from Bookswagon, do I need to be worried about any hidden charges like duty or sales taxes?</h1>
      <p>No, the customers have not to worry about any hidden charges while purchasing from Booksindya. The prices displayed on the website for all the items are final and fully inclusive. You pay the exact amount you see on the website.</p>

      <h1>5. What is the procedure for returning the purchased order?</h1>
      <p>To return products that are damaged in transit or in case a wrong product is delivered, please contact customer support by by Whatsapp on +917003810616 or mail us at  Booksindya2024@gmail.com within 3 working day of delivery. Any return requests later than that will not be entertained.</p>

      <p>    • You have 3 calendar days to return an item from the date you received       
it. To be eligible for a return, your item must be unused and in the same     
condition that you received it. Your item must be in the original  packaging. Your item needs to have the receipt or proof of purchase.</p>
       <p>No Problem, We accept returns in these cases within 3 days of date of delivery. We do ask that you please understand that shipping fees will not be reimbursed.</p>
       <p>Once the return is received the refund will be processed in 3 days and you can expect it in your same payment method within 7 days.</p>
       
       <p>Please send the return to: <br /> Kiran Sinha <br />
C/O Ravi Bhushan Srivastava <br />
Chiragora, Samshan Road, <br />
Behind Mission of Knowledge school, Hirapur, <br />
Dhanbad-826001. <br />
Mobile no-7003810616</p>

<p>Once the return is received from customer/buyer , We will ship you another copy of the same product within 24 hours.</p>
      
      <p>“Booksindya” cannot provide any replacement for OLD/USED book purchased from our websites.
</p>

<h1>7. When will I get my replacement order?</h1>
<p>You will receive the details about the pickup as soon we get the replacement request either by mail or by call from customer support. The replaced copy will be dispatched only after seller receive you package. You are asked to contact us instantly if you don’t get the replaced package within predefined time limit.</p>
       
       <h1>6. What is the procedure for replacing the purchased order?</h1>
       <p>Before dispatch, we check each of the products thoroughly so that you get the products in the condition you have ordered. However, we cannot rule out the possibility of damage to the product in transit (we try to minimize the same by packing the books properly and padding them with cardboard). </p>
       

       <p>Therefore, any product you have received which either has a manufacturing defect is damaged in transit or is not up to the mark in terms of quality is eligible for replacement. </p>
        
        <p>Once you have received a defective product, you can mail us at  Booksindya2024@gmail.com or WhatsApp us on +917003810616   within 48 hours of receipt of the product. Do mention the reason for such request and also provide us photos of the defect for quick redressal. </p>
        <p>• We will inspect your request and approve the replacement request, and detailed information shall be provided to you regarding the pickup and reshipment of your product . We promise that it will involve minimum hassle and will not be troublesome.
   <br /> Please send the return to: <br />
Kiran Sinha <br />
C/O Ravi Bhushan Srivastava <br /> 
Chiragora, Samshan Road, <br />
Behind Mission of Knowledge school, Hirapur, <br />
Dhanbad-826001. <br />
Mobile no-7003810616</p> <br />
        <p>Once the return is received from customer/buyer , We will ship you another copy of the same product within 24 hours.</p>
        <p>“Booksindya” cannot provide any replacement for OLD/USED book purchased from our websites.</p>
       
       <h1>7. When will I get my replacement order?</h1>
       <p>You will receive the details about the pickup as soon we get the replacement request either by mail or by call from customer support. The replaced copy will be dispatched only after seller receive you package. You are asked to contact us instantly if you don’t get the replaced package within predefined time limit.</p>
       

       <h1>8. When will I get my refund?</h1>
       <p>Booksindya  provides refund only in case of:
1. Damaged or Faulty Product(s) <br />
2. Wrong Product(s) delivered which are not as per your order <br />
3. Cancellation of order before dispatch</p> <br />

<p>Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item. </p>
       <p>Once the refund request is approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 3  days depending on the time taken by our payment gateway providers and banking channels.</p>
       <p>For orders placed through gift certificates or special coupon discounts, refund would be provided in form of a new gift certificate or store credit for the same value.</p>
        <p>Please note that postage/courier charges are not refundable.</p>
        <p>“Booksindya” reserves the right to refuse any refund wherein it is evident that the product has been used.</p>
        <p>“Booksindya” cannot provide any refund for OLD/USED book purchased from our websites.</p>
       
       <h1>9. What would be the delivery charges on Booksindya?</h1>
       <p>Delivery charges will vary from order to order depending on the book weight.</p>

       <h1>10. If I want to cancel my return request after initiating it, how can I do that?</h1>
       <p>As soon you feel like you don’t want to return the product and you wish to retain the product, you call us immediately requesting to cancel the return request.</p>

       <h1>11. Can I return some components of my order?</h1>
       <p>Yes of course. If you have orders more than one item in your order and you wish to return any one of them, you can do so. You need to inform us with the product details for partial order replacement.</p>

       <h1>12. Where I can find book information not listed on booksindya ?</h1>
       <p>We are here to help. If you cannot find the book you want, just drop us on @ Quick Support or email/Whatsapp with book ISBN / Title / Author any information helping us identify book and we shall revert to you with details of same. </p>

       <h1>13. How do I check status of my order ?</h1>
       <p>Once you place an order with us we will keep you informed about your order status via SMS/Email/What’sapp used while placing an order. After receiving the tracking detail you can track your order from the booksindya website. You can also call us for further details.</p>

       <h1>14. How long will it take for my order to be delivered ?</h1>
       <p>We try our best to deliver order within 2-3 business days, but considering the season and distance it may take 5-6 days.</p>
       

       <h1>15. Do I have to wait at my house or my office all day for my   delivery ?</h1>
       <p>No, our delivery partner will notify you prior to delivery. We will ensure the smooth delivery so all you can do is focus on treasure you are to receive.</p>
        
        <h1>16. Where I can find about special promotions, offers & discounts ?</h1>
        <p>You can find special codes and promotions on our social media channels, for special bulk discounts or events equiries you can write to us.</p>
<h1>17. What method of payments does booksindya accepts ?</h1>
<p>We support credit cards, debit cards, wallets and all associated payment methods provided by payment Gateway.</p>

        </section>

     

        <section id="join-community">
            <h2>Join Our Community</h2>
            <p>We invite you to become a part of our literary community. Explore our extensive catalog, participate in book discussions, and share your own book recommendations. We believe that every reader has a unique story to tell, and we're here to help you find your next favorite book.</p>
            <div className='d-flex gap-2 mb-3'>

          <a href="https://www.facebook.com/profile.php?id=61553364841273&mibextid=gjUFcU"> <button className='btn btn-sm btn-primary text-light' ><i className="fa-brands fa-facebook"></i></button></a> 
<button className='btn btn-sm btn-primary text-light'><i className="fa-brands fa-twitter"></i></button>
<a href="https://chat.whatsapp.com/DWVS5I0jUGS4IgyiTUmQ0h"><button className='btn btn-sm btn-success text-light'><i className="fa-brands fa-whatsapp"></i></button></a>
<button className='btn btn-sm btn-primary text-light'><i className="fa-brands fa-telegram"></i></button>
<a href="https://instagram.com/booksindya.in?utm_source=qr&igshid=MThlNWY1MzQwNA=="><button className='btn btn-sm btn-danger text-light'><i className="fa-brands fa-instagram"></i></button></a>
            </div>
        </section>
<section className='d-flex justify-content-center'>
   <PhotoAlbum layout="rows" photos={photos} />
</section>
    </main>

   </>
  )
}

export default About