import React from 'react'

const Loading = () => {
  return (
    <>
    <div className="container d-flex justify-content-center align-items-center" style={{height:"100vh"}}>

    <div className="loadingio-spinner-bean-eater-bmo59f5ir8p"><div className="ldio-cqs6a34ctgs">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
    </div>
    </>
  )
}

export default Loading