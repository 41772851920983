import React, { useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Itemcards from "./Itemcards";
import Context from "../context/Searchcontext";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

const Search = () => {
  let {search,page,length,setlength, setPage,items, setItems,hasMore, setHasMore}=useContext(Context)



  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {

    fetchData();
  }, [search]);

  const fetchData = () => {

    const url = `${process.env.REACT_APP_FETCH_API_ADDRESS}/getmy`;

    fetch(url,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },body:JSON.stringify({search:search,skip:page})
    })
      .then(response => response.json())
      .then(data => {
      setlength(data.data.length)
        let combinedArray = items.concat(data.data);
        let uniqueSet =[...new Set(combinedArray)];
        
        setItems(uniqueSet);
        setPage(prevPage => prevPage + 20);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const fetchMoreData = () => {
    fetchData();

  };

  useEffect(()=>{
if(length===0){
  setHasMore(false)
}
  },[page])
 

  return (
    <div className="my-4">

    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<img src="/Ripple-1.1s-64px.svg"/>}
      endMessage={<p>No more items to load</p>}
    >
      {items.map(ele => (
      <Itemcards   ele={ele}/>
      ))}
    </InfiniteScroll>
  </div>
  )
}

export default Search