import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import app from './Firebase';
import { getAuth, RecaptchaVerifier , signInWithPhoneNumber} from "firebase/auth";

import Loading from './Loading';
import {phone} from 'phone';
import * as EmailValidator from 'email-validator';
import schema from './validate';
const Signin = () => {

const [loading, setloading] = useState(false)



  const [alert, setalert] = useState(null)
const navigate=useNavigate()
  const [otp, setotp] = useState(false)

  const [check, setcheck] = useState(false)
  const [credntials, setcredntials] = useState({name:"",pno:"",email:"",password:"",cpassword:"",otp:""})
  useEffect(()=>{
    setTimeout(()=>{
      setalert(null)
        },5000)
  },[alert])


  const checkme=()=>{
    if(document.getElementById("flexCheckDefault").checked)
    {
      setcheck(true)
    }
    else{
      setcheck(false)
    }
  }
useEffect(() => {

if(localStorage.getItem("token"))
{
navigate("/")
}
}, [])


const startTimer=()=> {
  let timer;
  let duration = 120; 
let timerDisplay = document.getElementById("otpTimer");



let minutes, seconds;
timer = setInterval(function() {
  minutes = parseInt(duration / 60, 10);
  seconds = parseInt(duration % 60, 10);

  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  timerDisplay.textContent = minutes + ":" + seconds;

  if (--duration < 0) {
    clearInterval(timer);
    timerDisplay.textContent = "00:00";

    duration = 120; 
setalert({message:"Session Timeout",color:"danger"})
setTimeout(()=>{
window.location.reload()
},3000)
  }
}, 1000);
}







const handlechange=(e)=>{

  setcredntials({...credntials,[e.target.name]:e.target.value})
}


const captchaverify=()=>{
 
  const auth = getAuth();
  auth.languageCode = 'eng';

window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
  'size': 'invisible',
  'callback': (response) => {
    // reCAPTCHA solved, allow signInWithPhoneNumber.
    onSignInSubmit();
  }
});
}
 

  
const onSignInSubmit=(e)=>{
e.preventDefault()
setloading(true)

if(credntials.name.length<=5)
{
  setloading(false)
  setalert({message:"Name Must be 5 character",color:"danger"})
  return 
}
const Ind=phone(`${credntials.pno}`, {country: 'IND'});
if(!Ind.isValid)
{
  setloading(false)
  setalert({message:"Please Enter the valid Number",color:"danger"})
  return 
}

if(!schema.validate(credntials.password) || !schema.validate(credntials.cpassword))
{

  setloading(false)
  setalert({message:"Please Enter the valid Password",color:"danger"})
  return 
}
const email=EmailValidator.validate(`${credntials.email}`);
if(!email)
{
  setloading(false)

  setalert({message:"Please Enter the valid Number",color:"danger"})
  return 
}
if(credntials.password!==credntials.cpassword)
{
  setloading(false)

  setalert({message:"Password Does Not Matched",color:"danger"})
  return 
}



fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/check`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json"
  },body:JSON.stringify({pno:credntials.pno,email:credntials.email})
}).then((res)=>res.json()).then((ele)=>{
  if(ele.success){
    setalert({message:"Account Already Exist",color:"danger"})
    setloading(false)
    return
  }
  else{
    captchaverify()


    const phoneNumber = "+91"+credntials.pno;
    const appVerifier = window.recaptchaVerifier;
    
    const auth = getAuth();
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          setotp(true)
          setalert({message:"OTP Successfully Send To your Mobile",color:"success"})
          setloading(false)
          setTimeout(()=>{

            startTimer()
          },1000)
          window.confirmationResult = confirmationResult;
        }).catch((error) => {
         
         setalert({message:"Something Went Wrong Try again after sometime",color:"danger"})
         setloading(false)
        });
    




  }

})


  
  }

  const otpSubmit=(e)=>{
    e.preventDefault()
    setloading(true)
    const code = credntials.otp
    window.confirmationResult.confirm(code).then((result) => {
      // User signed in successfully.
      const user = result.user;
      if(user)
      {
        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/signup`,{
          method:"POST",
          headers:{
            "Content-Type":"application/json"
          },body:JSON.stringify({name:credntials.name,pno:credntials.pno,email:credntials.email,password:credntials.password,cpassword:credntials.cpassword})
        }).then((res)=>res.json()).then((ele)=>{
          if(ele.success)
          {
            
            setalert({message:ele.message,color:ele.color})
            setloading(true)
            setTimeout(()=>{

              navigate("/login")
            },2000)

            return
          }
          else{
            setalert({message:"Invalid Credentials",color:"danger"})
            setloading(false)
          }
        })
      }
      // ...
    }).catch((error) => {
      setalert({message:"OTP not Matched",color:"danger"})
      setloading(false)

    });
  }


  












  return (
   <>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Terms and Conditions</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body ">
      <div class="term-container">
    <h1>Terms and Conditions</h1>

    <h2>1. Acceptance of Terms:</h2>
    <p>By registering and logging in to our platform, you agree to abide by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.</p>

    <h2>2. Eligibility:</h2>
    <p>You must be at least 16 years old to register and use our services. By registering, you confirm that you are of legal age and capable of forming a binding contract.</p>

    <h2>3. User Account:</h2>
    <ul>
      <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
      <li>You agree to provide accurate and current information during the registration process.</li>
      <li>You are solely responsible for all activities that occur under your account.</li>
    </ul>

    <h2>4. Security:</h2>
    <ul>
      <li>You must notify us immediately of any unauthorized access or use of your account.</li>
      <li>We are not liable for any loss or damage arising from your failure to comply with security obligations.</li>
    </ul>

    <h2>5. Prohibited Activities:</h2>
    <ul>
      <li>You agree not to engage in any illegal, unauthorized, or abusive activities.</li>
      <li>Unauthorized access, data scraping, or any attempt to disrupt our services is strictly prohibited.</li>
    </ul>

    <h2>6. Privacy Policy:</h2>
    <ul>
      <li>Your use of our services is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.</li>
      <li>By using our services, you consent to the collection and use of your information as described in the Privacy Policy.</li>
    </ul>

    <h2>7. Termination:</h2>
    <ul>
      <li>We reserve the right to terminate or suspend your account at our discretion, without prior notice, for any violation of these terms.</li>
      <li>You may terminate your account at any time by following the provided procedures.</li>
    </ul>

    <h2>8. Modification of Terms:</h2>
    <ul>
      <li>We may modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting.</li>
      <li>Your continued use of our services after changes are made constitutes your acceptance of the modified terms.</li>
    </ul>

    <h2>9. Disclaimers:</h2>
    <ul>
      <li>Our services are provided "as is" without any warranty or guarantee.</li>
      <li>We are not responsible for any loss or damage resulting from the use of our services.</li>
    </ul>

   
  </div>
      </div>
  
    </div>
  </div>
</div>

{alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center `}style={{position:"sticky",top:"0"}} role="alert">
  <strong> {alert.message}</strong>
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
{loading && <Loading/>}
<div id="sign-in-button"></div>
{!loading &&

   <div className="container d-flex justify-content-center my-5">
  {!otp && <form onSubmit={onSignInSubmit} >

   <div className="signup-form">
  <Link to="/">  <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" /></Link>
    <h3>Create Account </h3>
    <div className="con1">
      <label htmlFor="fullname">Your Name</label>
      <input type="text"   name="name" id="name" maxLength={26} placeholder='First and last name' onChange={handlechange}/>
  
    </div>

    <div className="con1">
      <label htmlFor="pno">Mobile Number</label>
      <input type="tel" name="pno" id="pno" placeholder='Only Indian Mobile Number' minLength={10} maxLength={10} onChange={handlechange} />
   
    </div>
    <div className="con1">
      <label htmlFor="email">Email</label>
      <input type="email" name="email" id="email" placeholder='Enter Your Email' onChange={handlechange} />
  
    </div>

    <div className="con1">
      <label htmlFor="password">Password</label>
      <input type="password" name="password" id="password"  placeholder='Password must be length of 8' onChange={handlechange} />
  
    </div>

    <div className="con1 con2">
      <label htmlFor="cpassword">Confirm Password</label>
      <input type="password" name="cpassword"  id="cpassword" placeholder='Confirm Password' onChange={handlechange} />
      <p> <i className="fa-solid fa-info" style={{ color:"#2d03ed"}}></i><br /> Minimum length 8 <br />Maximum length 100 <br />Must have uppercase letters <br />Must have lowercase letters <br />Must have at least 2 digits <br />Should not have spaces <br /></p>
  
    </div>

    <div className="con2 d-flex flex-column row-gap-3">
      <p>To verify your number, we will send you a text message with a temporary code. Message and data rates may apply.</p>
      
      <div class="form-check">
  <input class="form-check-input" type="checkbox" onClick={checkme} id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault" >
  Please acknowledge that by logging in, you agree to adhere to the <span className='text-primary' data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{cursor:"pointer"}}>terms and conditions</span> specified on this website
  </label>
</div>

      <button type="submit" className={`btn btn-warning ${check?"":"disabled"}`} >Continue</button>

    </div>

    <div className="con2 d-flex column-gap-2 ">
      <p>
Already have an account? </p>
<Link to="/login" className='text-decoration-none' >Sign in <i className="fa-solid fa-caret-right" style={{color: "#1f33e4"}}></i></Link>
    </div>

   </div>
   </form>}






   {otp && <form onSubmit={otpSubmit} >

<div className="signup-form">
<a href="/login"> <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" /></a>
 <h3>Enter the OTP </h3>
 <div className="con1">
      <label htmlFor="pno">Enter OTP</label>
      <input type="tel" name="otp" id="otp" maxLength={6} minLength={6} placeholder='eg. 123456' onChange={handlechange}/>
  
    </div>
 <div className="con2 d-flex flex-column row-gap-3">
  <div className='d-flex w-100 gap-3 align-items-center'>
    <b>Session timeout In</b>
 <div id="otpTimer">00:00</div>
  </div>

   <button type="submit" className="btn btn-warning" >Submit</button>

 </div>


</div>
</form>}
    
   
   </div>
}
   </>
  )
}

export default Signin