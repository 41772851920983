
import React, { useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";


import Itemcards from "./Minorcomponents/Itemcards";
import Context from "./context/Searchcontext";
import { useLocation, useParams } from "react-router-dom";
const Combo = () => {
  const [length, setlength] = useState(1)
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);







let {id}=useParams()
let location=useLocation()


useEffect(()=>{
  document.documentElement.scrollTo({
    top:0,
    behavior:"smooth"
  })
  document.querySelector(".footer").style.display="none"
},[location])


useEffect(() => {
  setItems([])
  setPage(0)
  setHasMore(true)
  fetchData();
}, [id,location]);



const fetchData = () => {

  const url = `${process.env.REACT_APP_FETCH_API_ADDRESS}/getmy3`;

  fetch(url,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },body:JSON.stringify({search:"combo&offer",skip:page})
  })
    .then(response => response.json())
    .then(data => {
      
      let combinedArray = items.concat(data);
      let uniqueSet =[...new Set(combinedArray)];
      
      setItems(uniqueSet);
      setPage(prevPage => prevPage + 20); 
      setlength(data.length)
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchMoreData = () => {
  fetchData();
  
};

useEffect(()=>{
if(length===0){
setHasMore(false)
}
},[page])

  return (
    <div className="mt-4">

    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<img src="/Ripple-1.1s-64px.svg"/>}
      endMessage={<p>No more items to load</p>}
    >
      {items.map(ele => (
      <Itemcards   ele={ele}/>
      ))}
    </InfiniteScroll>
  </div>
  )
}

export default Combo