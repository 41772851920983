import React from 'react'

const Policy = () => {
  return (
    <>
    <section className='d-flex justify-content-center'>
    <div className="main-container">
      <div className="con">
        <img src="/truck-1.1s-200px.png" alt="truck" />
        <b>Free Shipping</b>
        <p>Enjoy the free shipping on most products</p>
      </div>
      <div className="con">
        <img src="/headset-1.1s-200px.png" alt="headset" />
        <b>24/7 Support</b>
        <p>We are always here get you supported</p>
      </div>
      <div className="con">
        <img src="/inr-1.1s-200px.png" alt="money" />
        <b>Quick Payment</b>
        <p>Fast, quick and simple payment process flow</p>
      </div>
      <div className="con">
        <img src="/gift-1.1s-200px.png" alt="gift" />
        <b>Gift Coupons</b>
        <p>Top coupons with top discounts for you</p>
      </div>
   
    </div>

    </section>
    </>
  )
}

export default Policy