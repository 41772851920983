import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Delivery = () => {
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  return (
<div className="policy2-container">
 
    <div className="section">
      <h2>DELIVERY POLICY</h2>
      <p>
        Deliveries are made all week except national holidays through Indian post services. We try our best to ensure that you receive your product within the stipulated time, however, if you do not receive your package within the specified time, you are requested to contact customer support by Whatsapp on <span className="highlight">+917003810616</span> or mail us at in <span className="highlight">Booksindya2024@gmail.com</span> within 2 working day of delivery.
        If you are not present at the time of delivery, <span className="highlight">Booksindya</span> will assume that any other family member at the provided address has been authorized to accept the orders on your behalf. In case that this isn’t possible or there is no one present at the time of delivery, we will not deliver the product and contact you to set up an alternate delivery time.
        Please note that our delivery personnel will deliver your products at your front door only.
        The delivery time is purely depends on many factors like distance, location, availability, etc. Generally the logistic partners takes 4-6 Business Days to deliver the products from our warehouse to the destination pin-codes throughout India, But if the destination pin-code is located at remote location then it may takes more time.
        If the item shows delivered in official website of the Indian post service but didn't received the package, In such cases users need to contact us through What’s app on <span className="highlight">+917003810616</span> or mail us at <span className="highlight">Booksindya2024@gmail.com</span> within 24 hour of delivery.
      </p>
    </div>


  
    </div>
  )
}

export default Delivery