import React, { useState } from 'react'

const Orderitem2 = (props) => {
    const [credentials, setcredentials] = useState({reason:""})
    const [alert, setalert] = useState(null)
    const handlechange=(e)=>{
        e.preventDefault()
        setcredentials({...credentials,[e.target.name]:e.target.value})
    }
    const handlesubmit=async(e)=>{
        e.preventDefault()
        if(credentials.reason.length<=10){
          setalert({message:"Reason minimum length 10 character"})
          setTimeout(()=>{
  setalert(null)
          },[])
          return
        }
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/cancelrequest`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
    },body:JSON.stringify({reason:credentials.reason,id:props.ele._id})
}).then((res)=>res.json()).then((ele)=>{
    if(ele.success)
    {
   window.location.reload()
    }
})
    }




    const handlesubmit2=async(e)=>{
      e.preventDefault()

      if(credentials.reason.length<=10){
        setalert({message:"Reason minimum length 10 character"})
        setTimeout(()=>{
setalert(null)
        },[])
        return
      }
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/replacementrequest`,{
  method:"POST",
  headers:{
      "Content-Type":"application/json",
      "token":localStorage.getItem("token")
  },body:JSON.stringify({reason:credentials.reason,id:props.ele._id})
}).then((res)=>res.json()).then((ele)=>{
  if(ele.success)
  {
 window.location.reload()
  }
})
  }
  return (
    <>
    {alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong><i className="fa-regular fa-thumbs-up"></i></strong> {alert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    <div className="modal fade" id={`staticBackdrop${props.ele._id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
     

      <div className="modal-body w-100">
        <label htmlFor="reason">Reason for Return & Replacement</label>
   <textarea name="reason" className='w-100' id="reason" onChange={handlechange} ></textarea>
      </div>
      <div className="modal-footer">
        
        <button type="submit" onClick={handlesubmit} className="btn btn-danger">Return</button>
        <button type="submit" onClick={handlesubmit2} className="btn btn-success">Replace</button>
      </div>
  
    </div>
  </div>
</div>

 <div className="order-card mt-4">
   <div className="top-order">
    <b>#Order Id</b>
    <b>Items</b>
    <b>Quantity</b>
    <b>Item price</b>
    <b>Total price</b>
    {props.ele.request && <b>Status</b>}
    {!props.ele.request && <b>Action</b>}
    
   </div>
   <div className="bottom-order">
   <div>

   <b>{props.ele.order_id}</b>
   </div>

   <div >
    {props.ele.order_detail.map((ele,i)=>{
      return <b key={i}><br />{ele}</b>
    })}
   </div>

   <div>
   {props.ele.quantity.map((ele,i)=>{
     return <b key={i}><br />{ele}</b>
    })}
   </div>

   <div >
   {props.ele.itemprice.map((ele,i)=>{
     return <b key={i}><br /><i className="fa-solid fa-indian-rupee-sign"></i>{ele.toFixed(2)}</b>
    })}
   </div>

   <div>
    <b><i className="fa-solid fa-indian-rupee-sign"></i>{parseFloat(props.ele.total_amount).toFixed(2)}</b>
   </div>

  {props.ele.request && <div>
    <b>{props.ele.status}</b>
   </div>}
  {!props.ele.request && <div>
    <button type="button"  data-bs-toggle="modal" data-bs-target={`#staticBackdrop${props.ele._id}`} style={{whiteSpace:"nowrap"}} className='btn btn-warning btn-sm'>Cancel/Replacement Request</button>
   </div>}
 </div>
    </div> 
 </>
  )
}

export default Orderitem2