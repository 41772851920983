import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loading from './Loading'
import {phone} from 'phone';
import Context from '../context/Searchcontext';
import { useContext } from 'react';
const Login = () => {
const [alert, setalert] = useState(null)
const [credntials, setcredntials] = useState({pno:"",password:""})
const [loading, setloading] = useState(null)
const context=useContext(Context)
const {token,settoken}=context

useEffect(() => {
  if(localStorage.getItem("token"))
  {
    navigate("/")
  }
}, [])
useEffect(()=>{
setTimeout(()=>{
setalert(null)
},2000)
},[alert])


 const navigate=useNavigate()
  const handlechange=(e)=>{
    setcredntials({...credntials,[e.target.name]:e.target.value})
  }

  const handlesubmit=async(e)=>{
    e.preventDefault()
    const Ind=phone(`${credntials.pno}`, {country: 'IND'});
    if(!Ind.isValid)
    {
  setloading(false)

      setalert({message:"Please Enter the valid Number",color:"danger"})
      return 
    }
 
    setloading(true)

    const response=await fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/login`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json"
  },
  body:JSON.stringify({pno:credntials.pno,password:credntials.password})
})
const json=await response.json()
if(json.success)
{
  
  setalert({color:"success",message:`Welcome back, ${json.name}! You are now logged in.`})
  
  localStorage.setItem("token",json.token)
  localStorage.setItem("name",json.name)
  localStorage.setItem("email",json.email)
  localStorage.setItem("pno",json.pno)
  setTimeout(()=>{
  
    navigate("/")
  },4000)
return
}

  setalert({color:"danger",message:`Invalid Credentials`})
  setloading(false)



  }

  return (
    <>
    
{alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong>{alert.message}</strong> 
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}

{loading && <Loading/>}

{ !loading &&
    <div className="container d-flex justify-content-center my-5">

 <form onSubmit={handlesubmit} >
 
    <div className="signup-form">
    <Link to="/"> <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" /></Link>
     <h3>Sign In </h3>

 
     <div className="con1">
       <label htmlFor="pno">Mobile Number</label>
       <input type="tel" name="pno" id="pno" placeholder='Only Indian Mobile Number' minLength={10} maxLength={10} onChange={handlechange} />
   
     </div>

 
     <div className="con1">
       <label htmlFor="password">Password</label>
       <input type="password" name="password" id="password" placeholder='At Least 6 characters' minLength={6} onChange={handlechange} />
   
     </div>
 

 
     <div className="con2 d-flex flex-column row-gap-3">

       <button type="submit" className="btn btn-warning">Continue</button>
 
     </div>

     <div className="con1">
    <Link to="/forget" className='text-decoration-none text-center' >Forget Password</Link>
 </div>

     <div className="con2 d-flex column-gap-2 ">
       <p>
 No I Have No Account </p>
       <Link to="/signup" className='text-decoration-none' >Sign Up <i className="fa-solid fa-caret-right" style={{color: "#1f33e4"}}></i></Link>
     </div>

    </div>
    </form>

  
    </div>
}
    </>
  )
}

export default Login