import React from 'react'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js';
const Itemcards2 = (props) => {
    const handledelete=(e)=>{
        e.preventDefault()
        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/deletefromwishlist`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "token":localStorage.getItem("token")
            },body:JSON.stringify({bookid:props.ele._id})
        }).then((res)=>res.json()).then((ele)=>{
            if(ele.success)
            {
                window.location.reload()
            }
        })
    }


  function sliceFiveWords(inputString) {
    // Split the string into an array of words
    var words = inputString.split(" ");

    // Select the first five words and join them back into a string
    var slicedString = words.slice(0, 5).join(" ");

    return slicedString;
}
  return (
    <>
    <Link className='text-decoration-none text-dark fs-6 ' to={`/productpage/${encodeURIComponent(CryptoJS.AES.encrypt(props.ele._id, process.env.REACT_APP_PRODUCT_KEY).toString())}`}>
    <div className="small-cards">
    <img src={props.ele.imgtext1} alt="itemimg"  />
        <p>{sliceFiveWords(props.ele.booktitle)}</p>
        <div className='d-flex gap-2'>
          
        <h4 className='text-danger fw-bolder  fs-5 text-decoration-line-through d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{props.ele.mrp}</h4>
        <h4 className='text-success fw-bolder fs-5 d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{props.ele.actualprice}</h4>
        <h5 className='text-success fw-bolder fs-5 d-flex  '>{props.ele.discount} <i className="fa-solid fa-percent"></i></h5>
        </div>
        <b className='text-danger pointer' onClick={handledelete}>Remove from wishlist</b>
    </div>
    </Link>
    
    </>
  )
}

export default Itemcards2
