import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Leftcontainer from "./Minorcomponents/Leftcontainer";

import Crousal from "./Minorcomponents/Crousal";

import Context from "./context/Searchcontext";
import { useContext } from "react";

import Offer from "./Minorcomponents/Offer";
import Policy from "./Minorcomponents/Policy";
import Leftcontainer2 from "./Minorcomponents/Leftcontainer2";

import Testimonial from "./Minorcomponents/Testimonial";
import Blog from "./Minorcomponents/Blog";

import Newarrival from "./Minorcomponents/Newarrival";
import Gridcontainer from "./Minorcomponents/Gridcontainer";
import Gridcontainer2 from "./Minorcomponents/Gridcontainer2";
import Gridcontainer3 from "./Minorcomponents/Gridcontainer3";
import Gridcontainer4 from "./Minorcomponents/Gridcontainer4";
import Gridcontainer5 from "./Minorcomponents/Gridcontainer5";
import Gridcontainer7 from "./Minorcomponents/Gridcontainer7";
import Gridcontainer8 from "./Minorcomponents/Gridcontainer8";
import Gridcontainer9 from "./Minorcomponents/Gridcontainer9";
import Banner from "./Banner";

const Home = () => {
  const context=useContext(Context)
  const {x}=context
  const location=useLocation()
const [loading, setloading] = useState(false)
  useEffect(()=>{
    document.querySelector(".footer").style.display="none"
setTimeout(()=>{
  document.querySelector(".footer").style.display="block"
setloading(false)
},1000)
  },[])

  return (
    <>
    {loading && <div className="d-flex justify-content-center mt-4"><img  src="/Eclipse-1s-137px.svg"  /></div>}
  {!loading && <div>

    <main>
      <Banner/>
    <Crousal/>
    <Newarrival/>
    <Offer/>
    <Gridcontainer/>
  <Gridcontainer2/>
     



    <Gridcontainer3/>
    <Gridcontainer4/>
    <Gridcontainer5/>

    <Leftcontainer/>

     <Leftcontainer2/>
    <Gridcontainer7/>
    <Gridcontainer8/>
    {/* <Gridcontainer9/> */}
<Testimonial/>
    <Blog/>
    <Policy/>
    </main>
  </div>}
    
    </>
  );
};

export default Home;
