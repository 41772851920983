import React from 'react'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <section className='blog-section'>
    <div class="heading">
        <h1>Blog</h1>
        <div class="underline"></div>
    </div>
    <div class="blog-container">
        <div class="blog-box">
            <Link to="/narendra-modi" target="_blank">
                <img src="/images/Blog on India's Prime Minister Narendra Modi.jpg" alt=""/>
            </Link>
        </div>
        <div class="blog-box">
            
            <Link to="/bhagwad-gita" target="_blank">
                <img src="/images/Blog on Srimad Bhagavad Gita.jpg" alt=""/>
            </Link>
        </div>
        <div class="blog-box">
            <Link to="/SCC" target="_blank">
                <img src="/images/Blog on How to Prepare for SSC Examination.jpg" alt=""/>
            </Link>
        </div>
        <div class="blog-box">
            <Link to="/RRB" target="_blank">
                <img src="/images/Blog on How to Prepare for Railway Examination.jpg" alt=""/>
            </Link>
        </div>
    </div>
</section>
  )
}

export default Blog