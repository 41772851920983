import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import CryptoJS from 'crypto-js';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';




import { Autoplay, Pagination , Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Newarrival = () => {
const [data, setdata] = useState([])
const [loading, setloading] = useState(true)
   
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/newarrival`,{
            method:"POST",
            headers:{
              "Content-Type":"application/json"
            },body:JSON.stringify({search:"books"})
          }).then((res)=>res.json()).then((ele)=>{
        
            setdata(ele)})
       
    },[])


  return (
   <section className='d-flex justify-content-center'>
    <div className="big-new-arrival-container" >
  <a href="/newarrival" className='text-decoration-none '>   <h1 className=' title-hover' style={{backgroundColor:"#FF9843"}}><i className="fa-solid fa-book"></i> NEW ARRIVAL</h1></a>
    <div className='new-arrival' id='new-arrival-container'>
    <Swiper
    navigation={true}
        slidesPerView="auto"
        spaceBetween={30}
        loop={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination,Autoplay,Navigation]}
        className="mySwiper"
      >


{
            data.length!==0 && data.map((ele,i)=>{

                return   <SwiperSlide key={i}> <Link  ele={ele} to={`/productpage/${encodeURIComponent(CryptoJS.AES.encrypt(ele._id, process.env.REACT_APP_PRODUCT_KEY).toString())}`} className="arrivalcon"><img src={ele.bimg1} alt="" /></Link></SwiperSlide>
            })
        }
      
    
   
      </Swiper>


        
        
    </div>
    </div>
   </section>
  )
}

export default Newarrival