import React from 'react'

const Error = () => {
  return (
    <main>
        <section className='d-flex justify-content-center'>
            <img className='error' src="/404 Brain Not Found T-Shirt .png" alt="errorpage"  />
        </section>
    </main>
  )
}

export default Error