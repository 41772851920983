import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from './Loading'
import * as EmailValidator from 'email-validator';
const Contact = () => {
  const [credntials, setcredntials] = useState({query:"",name:"",pno:"",email:""})
  const [loading, setloading] = useState(false)
  const [alert, setalert] = useState(null)
  
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  const handlechange=async(e)=>{
    setcredntials({...credntials,[e.target.name]:e.target.value})
  }
  const navigate=useNavigate()

  useEffect(()=>{
    setTimeout(()=>{
    setalert(null)
    },2000)
    },[alert])
    
  const onsubmit=async(e)=>{
    let x=document.getElementById("name")
    let y=document.getElementById("email")
    let z=document.getElementById("sugg")
    const email=EmailValidator.validate(`${credntials.email}`);
if(!email)
{
  setloading(false)

  setalert({message:"Please Enter the valid Number",color:"danger"})
  return 
}

    if(x.value.length<3 || y.value.length<3 || z.value.length<6)
    {
      setalert({message:"Invalid Credentials",color:"danger"})
      return
    }
    e.preventDefault()

    fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/contactus`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        
      },body:JSON.stringify({suggestion:credntials.query,email:credntials.email,pno:credntials.pno,name:credntials.name})
    }).then((res)=>res.json()).then((res)=>{
      setalert({message:res.message,color:res.color})
      setloading(true)
      setTimeout(()=>{
        setalert(null)
        setloading(false)
        navigate("/")
        
        },3000)
    })
  }



  return (
    <>
    {alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`} style={{position:"sticky",top:"0"}} role="alert">
  <strong><i className="fa-regular fa-thumbs-up"></i></strong> {alert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}

{loading && <Loading/>}
{!loading &&

    <main>
      <section className='d-flex justify-content-center'>
      <div className="container d-flex justify-content-center my-5">

<form  onSubmit={onsubmit}>

   <div className="signup-form" >
   <Link to="/"> <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" /></Link>
    <h3>Contact Us</h3>


    <div className="con1">
    <div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Your Name</label>
  <input type="text" className="form-control" name='name' value={credntials.name} onChange={handlechange} id="name" rows="3"></input>
</div>

<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Your Email</label>
  <input type="email" className="form-control" name='email' value={credntials.email} onChange={handlechange} id="email" rows="3"></input>
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Mobile Number</label>
  <input type="tel" className="form-control" name='pno' value={credntials.pno} onChange={handlechange} id="pno" rows="3"></input>
</div>
      <div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Your Query</label>
  <textarea className="form-control" name='query' value={credntials.query} onChange={handlechange} id="sugg" rows="3"></textarea>
</div>
  
    </div>


  


    <div className="con2 d-flex flex-column row-gap-3">

      <button type="submit" className="btn btn-warning">Submit</button>

    </div>
  <h4 className='con-info'><b>Contact information</b></h4>
  <p>If you prefer to reach us through other means, you can find our contact details below:</p>
 <ul>
  <li>Email: Booksindya2024@gmail.com</li>
  <li>Phone: +91 7003810616</li>
  <li>Address: Chiragora, Samshan Road,
Behind Mission of Knowledge school, Hirapur,
Dhanbad-826001
</li>
 </ul>
  
   </div>
   </form>

 

   </div>
      </section>
    </main>
}
    </>
  )
}

export default Contact