import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Replacement = () => {
    let location=useLocation()
    useEffect(()=>{
      document.documentElement.scrollTo({
        top:0,
        behavior:"smooth"
      })
    },[location])
  return (
    <div className="policy2-container">
    <div className="section">

        
        <h2>REPLACEMENT POLICY</h2>
        <p>
        Before dispatch, we check each of the products thoroughly so that you get the products in the condition you have ordered. However, we cannot rule out the possibility of damage to the product in transit (we try to minimize the same by packing the books properly and padding them with cardboard). 
        </p>

<p>Therefore, any product you have received which either has a manufacturing defect is damaged in transit or is not up to the mark in terms of quality is eligible for replacement. </p>


<p>Once you have received a defective product, you can mail us at  <span className='text-primary'>Booksindya2024@gmail.com</span > or WhatsApp us on <span className='text-primary'>+917003810616 </span>  within 48 hours of receipt of the product. Do mention the reason for such request and also provide us photos of the defect for quick redressal. </p>


<p>We will inspect your request and approve the replacement request, and detailed information shall be provided to you regarding the pickup and reshipment of your product . We promise that it will involve minimum hassle and will not be troublesome.</p>


    <p>Once the return is received from customer/buyer , We will ship you another copy of the same product within 24 hours.</p>


    <p>“Booksindya” cannot provide any replacement for OLD/USED book purchased from our websites.</p>    
    </div>

  
    </div>
  )
}

export default Replacement