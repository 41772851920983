import React, { useCallback, useEffect, useState } from 'react'
import useRazorpay from "react-razorpay";
const Finalorder = () => {
    const [Razorpay] = useRazorpay();
const [order, setorder] = useState(null)
const [key, setkey] = useState(null)
    const createorder=(e)=>{

        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/createorder`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },body:JSON.stringify({amount:e.target.value})
        }).then((res)=>res.json()).then((ele)=>{
            setorder(ele)
            
        })


        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/authentication`,{
            method:"POST",
        }).then((res)=>res.json()).then((ele)=>{
           setkey(ele.key)
            
        })
    }






      useEffect(()=>{
        if(order)
        {
            const options= {
                key: key,
                amount: order.amount,
                currency: "INR",
                name: "Acme Corp",
                description: "Arihant Books Mathematics",
                image: "https://st2.depositphotos.com/1001911/7684/v/450/depositphotos_76840867-stock-illustration-pointing-at-himself-emoticon.jpg",
                order_id: order.id,
                "handler": function (response){
                 fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/`).then((res)=>res.json()).then((ele)=>console.log(ele))
              },
                redirect:false,
                prefill: {
                  name: "PiyushGarg",
                  email: "lk090631@gmail.com",
                  contact: "8826036343",
                  address:"rzr-68 chanakya place part 2"
                },
                notes: {
                  address: "Razorpay Corporate Office",
                },
                theme: {
                  color: "#3399cc",
                },
              };
          
              const rzpay = new window.Razorpay(options);
              rzpay.open();
        }
            },[order])
  return (
   <>
   <button type="button" onClick={createorder}  value={500}>Pay Now</button>
   
   </>
  )
}

export default Finalorder