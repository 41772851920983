import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Itemcards2 from './Itemcards2'
const Wishlist = () => {
    const [index, setindex] = useState(11)
    const [loading, setloading] = useState(false)
    const [finaldata, setfinaldata] = useState([])
    const [data, setdata] = useState([])
    const navigate=useNavigate("/")
   const location= useLocation()
    useEffect(()=>{
if(!localStorage.getItem("token"))
{
    navigate("/login")
}
if(location.pathname===`/wishlist`)
{
    
    document.querySelector(".footer").style.display="none"
}
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/itemsfromwishlist`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")   
    }
}).then((res)=>res.json()).then((ele)=>{
   
    setdata(ele)
    
})
    },[])


    useEffect(()=>{
        setfinaldata(data.slice(0,10))
        },[data])

        useEffect(()=>{
            window.addEventListener("scroll",()=>{
                if(document.documentElement.scrollTop+window.innerHeight+1>=document.documentElement.scrollHeight)
                {
                    
                    if(index<data.length)
                    {
                        setloading(true)
                        setTimeout(()=>{
                            
                            setfinaldata([...finaldata,...data.slice(index,index+5)])
                        setloading(false)
                        },2000)
                        setindex(index+5)
                    }
                
                }
            })
            },[finaldata])  
            
  return (
   <>
 <main>
    <section className='d-flex flex-column align-items-center justify-content-center my-4'>
    <div className="product-items justify-content-center">
       {finaldata.length!=0 && finaldata.map((ele)=>{
        return <Itemcards2 key={ele._id} ele={ele}/>
       })}
   
    </div>
    {
        finaldata.length==0 && <img src="/dribble_shot_62_4x.jpg" className='wishlist img w-100' alt="wishlist"  />
       }
        {loading && <img src="/ourloading.gif" className='mb-3' width="150px" alt="loading"  />}
    </section>
   </main>
   </>
  )
}

export default Wishlist