import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Orderitem from './Orderitem'
import { useState } from 'react'


const Orderhistory = () => {
    const location=useLocation()
    const navigate=useNavigate()
    const [data, setdata] = useState([])
    useEffect(()=>{
if(!localStorage.getItem("token"))
{
navigate("/login")
}
if(location.pathname=="/orderhistory")
{
document.querySelector(".footer").style.display="none"
}
    },[])

    useEffect(()=>{
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/orderhistory`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
    }
}).then((res)=>res.json()).then((ele)=>{
   
    setdata(ele.order)})
    },[])
  return (
    <main>
        <section className='d-flex justify-content-center'>
            <div className="myorder-container mt-3">

{data.map((ele)=>{
    return <Orderitem key={ele._id} ele={ele} />
})}


{!data.length && <div className='d-flex flex-column align-items-center mt-3'>
<h1>No Previous Purchase</h1>
<img id='itemnotfound' src="/itemnotfound.png" alt="itemnotfound" />
</div>
}
            </div>

</section>
    </main>
  )
}

export default Orderhistory