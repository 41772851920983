import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Toppublisher = () => {
    const [data, setdata] = useState([])
    useEffect(()=>{

fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/displaybrand`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json"
    }
}).then((res)=>res.json()).then((ele)=>{
    setdata(ele.data)
})
    },[])
  return (
    <>
  <h1 className='my-3 text-center'>Top Publishers</h1>
  <div className='d-flex justify-content-center '>
  <div className="toppublisher-container">
    

{
    data.length!==0 &&  data.map((ele,i)=>{
        return <a className='publisher-link' href={`/ourpublisher/${ele.name}`}>
         <div key={ele._id} className="pub-con">
        <img src={ele.image} alt="publisher"  />
    </div>
      <b>{ele.name.replace("Publication","")}</b>
        </a>
    }) 

}

   
 
  </div>
  </div>
    </>
  )
}

export default Toppublisher