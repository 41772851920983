import React, { useContext, useEffect,useState } from 'react'
import Context from './context/Searchcontext';

const Banner = () => {
  let {showPopup, setShowPopup}=useContext(Context)

  console.log(showPopup)
    const handleClose = () => {
      setShowPopup(false);
    }
  return (
<>

<div className={`popup-banner ${showPopup ? 'active' : ''}`}>
      <div className="popup-content">
        <h2>Download our Android App!</h2>
        <p>Get the best experience by downloading our app.</p>
        <img src="/Application_Banner.jpg" alt="Application_Banner" />
        <a href="https://play.google.com/store/apps/details?id=com.grabs4buisness.booksindya&pcampaignid=web_share" className="download-button">Download Now</a>
        <button className="close-button" onClick={handleClose}>&times;</button>
      </div>
    </div>
</>
  )
}

export default Banner