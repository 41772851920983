import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Shipping = () => {
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  return (
<div className="policy2-container">
    <div className="section">
      <h2>SHIPPING POLICY</h2>
      <p>
        Shipping will be initiated within 24 hours of order receiving. Normal delivery time will be 5-6 days, for local it will be 2-3 days.
        For successfully orders, Customer needs to fill the correct address in below format at the time of placing an order or checkout. While entering the address users need to take special attention to fill proper address i.e. Full Name, C/O (Parents / Guardian Name), House No, Landmark, Mobile Number and others address related details etc.
        <span className="highlight">Booksindya</span> assure users to deliver the ordered product from Monday to Saturday in-between 7AM - 9PM throughout all pin-codes in India at best & challenging price.
        You will receive a notification from us via email or What’s app with the tracking details once your books have been shipped.
        You can then click on the ‘Track your order’ link provided at the website (Booksindya.in) to track your order until it reaches you.
        For your order to reach you in the fastest time and in good condition, we only ship through Indian post service.
        We make our best efforts to ship each item in your order within 1 to 2 working days of the order. However in some cases, we may take longer, up to 3 to 4 working days, to ship the order if a certain product is out of stock or will be available soon.
        <span className="highlight">Booksindya</span> generally process all orders separately as per the Order Ids, But sometimes if we received any multiple orders from the same Users Email Accounts with multiple order Ids but the delivery address are same. In that cases <span className="highlight">Booksindya</span> merge the order Ids & ship it in one box / packaging which will help the users to collect it in single package instead of collecting multiple packages.
      </p>
      <h3 className="key-points">Shipping Rates:</h3>
      <p className="key-points">
        Shipping Rates are calculated on the total weight of all books on the cart.
        We do not charge any excess shipping rate, the shipping charges charged by the courier companies is forwarded to you.
      </p>
      <p className="key-points">No Cash on Delivery(COD) payment service facility are available in our website.</p>
    </div>
    
    </div>
  )
}

export default Shipping