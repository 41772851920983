import React from 'react'
import Context from './Searchcontext'
import { useState } from 'react'
const Searchstate = (props) => {
const [search, setsearch] = useState("book")
const [alert, setalert] = useState(null)
const [page, setPage] = useState(0);
const [items, setItems] = useState([]);
const [length, setlength] = useState(1)
const [change, setchange] = useState(0)
const [hasMore, setHasMore] = useState(true);
const [showPopup, setShowPopup] = useState(true);
const [discountprice, setdiscountprice] = useState(0)
const [mycode, setmycode] = useState('')
  return (
<Context.Provider value={{mycode, setmycode,discountprice, setdiscountprice,change, setchange,search,setsearch,setalert,alert,page, setPage,items, setItems,length, setlength,hasMore, setHasMore,showPopup, setShowPopup}}>
{props.children}
</Context.Provider>
  )
}

export default Searchstate