import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Itemcards from "./Minorcomponents/Itemcards";
import Context from "./context/Searchcontext";
import { useLocation, useParams } from "react-router-dom";

const Products = () => {
  const [length, setLength] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  let { search, page, setPage, items, setItems } = useContext(Context);
  let { id } = useParams();
  const [myid, setmyid] = useState(id)
  let location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.querySelector(".footer").style.display = "none";
  }, [location]);

  useEffect(() => {
setmyid(id)
  }, [location])
  
  useEffect(() => {
    setItems([]);
    setPage(0);
    setHasMore(true);
    fetchData();
  }, [myid, location]);

  const fetchData = () => {
    const url = `${process.env.REACT_APP_FETCH_API_ADDRESS}/getmy`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ search: myid, skip: page }),
    })
      .then((response) => response.json())
      .then((data) => {
        let combinedArray = items.concat(data.data);
        let uniqueSet = [...new Set(combinedArray)];

        setItems(uniqueSet);
        setPage((prevPage) => prevPage + 20);
        setLength(data.data.length);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchMoreData = () => {
    fetchData();
  };

  useEffect(() => {
    if (length === 0) {
      setHasMore(false);
    }
  }, [length]);

  return (
    <div className="mt-4">
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<img src="/Ripple-1.1s-64px.svg" alt="Loading..." />}
        endMessage={<p>No more items to load</p>}
      >
        {items.map((ele, index) => (
          <Itemcards key={index} ele={ele} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Products;
