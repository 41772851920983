import React from 'react'

const Narendramodi = () => {
  return (
    <div class="blog-mainContainer">
    <div class="blogmain-text-container">
        <h1>India's Prime Minister: Narendra Modi</h1>
        <div class="blogmain-text">
                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span>
                    Narendra Damodardas Modi (born September 17, 1950, Vadnagar, India) is an Indian politician and government official
                    who has served as the 14th prime minister of India since May 2014. Modi was raised in a small town in northern Gujarat,
                    and he completed an M.A. degree in political science from Gujarat University in Ahmadabad. He was introduced to the
                    Rashtriya Swayamsevak Sangh (RSS) at the age of eight and became a full-time worker for the RSS in Gujarat in 1971. The
                    RSS assigned him to the Bharatiya Janata Party (BJP) in 1985 and he held several positions within the party hierarchy
                    until 2001, rising to the rank of general secretary .
                </p>

                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span>
                    Before taking over as the PM, Modi was the Chief Minister of the Indian state of Gujarat for three consecutive terms
                    (2001 to 2014). While his policies as chief minister, which were credited for encouraging economic growth, were praised,
                    Modi's administration was criticised for failing to significantly improve health, poverty and education indices in the
                    state.
                </p>

                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span>
                    In 1990 Modi was one of the BJP members who participated in a coalition government in the state, and he helped the BJP
                    achieve success in the 1995 state legislative assembly elections that in March allowed the party to form the first-ever
                    BJP-controlled government in India.
                </p>

               <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span>
                His administration is considered complicit in the 2002 Gujarat riots,and has been criticised for its management of the
                crisis. A little over 1,000 people were killed, according to official records, three-quarters of whom were Muslim;
                independent sources estimated 2,000 deaths, mostly Muslim.A Special Investigation Team appointed by the Supreme Court of
                India in 2012 found no evidence to initiate prosecution proceedings against him.
               </p>

               <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span>
                In 2014 he led his party to victory in elections to the Lok Sabha (lower chamber of the Indian parliament), after
                which he was sworn in as prime minister of India. Modi began a high-profile sanitation campaign, controversially
                initiated the 2016 demonetisation of high-denomination banknotes and introduced the Goods and Services Tax, and weakened
                or abolished environmental and labour laws. Under PM Modi, the Centre has also taken a number of landmark decisions,
                right from the abolishment of Article 370 in Jammu & Kashmir to introducing the women's reservation bill in Parliament.
               </p>

                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span>
                    The Narendra Modi led Bharatiya Janata Party swept to victory in the recent 2019 Lok Sabha elections and increased its
                    majority in the lower house of Parliament. The BJP won 303 out of 542 Lok Sabha constituencies, further increasing its
                    substantial majority and the BJP-led National Democratic Alliance (NDA) won 353 seats.
                </p>

                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span>
                    PM Modi has led the country through a number of significant achievements in recent years. Here are a few examples:
                </p>


               <p>
                <span>(i)</span> Economic growth: India has experienced rapid economic growth under Modi's leadership. In 2022, the
                Indian economy grew
                at a rate of 8.7%, making it the fastest-growing major economy in the world.
               </p>

                <p>
                    <span>(ii)</span> Infrastructure development: Modi has made significant investments in infrastructure development,
                    including roads,
                    railways, airports, and ports. This has helped to improve connectivity and boost economic growth.
                </p>

             <p>
                <span>(iii)</span> Digital India: Modi has launched a number of initiatives to promote digitalization in India, including
                the Digital India
                program and the Aadhaar biometric ID system. This has helped to improve access to government services and boost the
                digital economy.
             </p>

                <p>
                    <span>(iv)</span> Swachh Bharat Abhiyan: Modi launched the Swachh Bharat Abhiyan (Clean India Mission) in 2014 with the
                    goal of making
                    India open defecation free by 2019. The mission has been successful in reducing open defecation by over 90%.
                </p>

                <p>
                    <span>(v)</span> Jan Dhan Yojana: PM Jan Dhan scheme has widened banking net to poor and has ensured financial inclusion.
                    The JAM trinity
                    (Jan Dhan- Aadhaar- Mobile) has led to elimination of middle-men and enhanced transparency. . The scheme has been
                    successful in opening over 450 million bank accounts, many of them for the poor and unbanked.
                </p>

               <p>
                <span>(vi)</span> Make in India: He launched the Make in India initiative in 2014 to promote manufacturing in India. The
                initiative has
                attracted significant foreign investment and helped to boost the manufacturing sector.
               </p>

                <p>
                    <span>(vii)</span> Foreign policy: PM Modi has pursued a more assertive foreign policy than his predecessors. He has
                    strengthened ties with
                    major powers such as the United States, Japan, and Australia. He has also played a leading role in regional
                    organizations such as the South Asian Association for Regional Cooperation (SAARC) and the Bay of Bengal Initiative for
                    Multi-Sectoral Technical and Economic Cooperation (BIMSTEC).
                </p>

                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span> The Pradhan Mantri Ujjwala Yojana has proven to be a major
                    game-changer in providing smoke-free kitchens by providing
                    free cooking gas connections to the poor. 18,000 villages that were without electricity even after 70 long years of
                    Independence have been electrified.

                </p>
                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span> Narendra Modi is one of the most followed names on social media
                    platforms like Facebook and Twitter. A significant
                    aspect of Modi's tenure has been his focus on foreign policy and international relations. He has actively engaged with
                    world leaders, promoting India's interests on the global stage. Modi has sought to strengthen bilateral ties, forge
                    strategic partnerships, and enhance India's standing as a global power. Notable initiatives include the establishment of
                    the International Solar Alliance and increased cooperation with African nations.
                </p>

                <p>
                    <span><i class="fa-solid fa-feather-pointed"></i></span> Narendra Modi has effectively utilized social media platforms
                    to directly communicate with the citizens of India,
                    bypassing traditional media channels. Through his monthly radio program "Mann Ki Baat", Modi addresses a wide range of
                    issues and shares his thoughts with the nation. This direct communication approach has helped him maintain a strong
                    connection with the people and project a relatable image.
                </p>

               <p>
                <span><i class="fa-solid fa-feather-pointed"></i></span> Narendra Modi has effectively utilized social media platforms
                to directly communicate with the citizens of India,
                bypassing traditional media channels. Through his monthly radio program "Mann Ki Baat", Modi addresses a wide range of
                issues and shares his thoughts with the nation. This direct communication approach has helped him maintain a strong
                connection with the people and project a relatable image.
               </p>

            
        </div>
    </div>
</div>
  )
}

export default Narendramodi