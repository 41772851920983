import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import CryptoJS from 'crypto-js';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Grid, Pagination,Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Gridcontainer5 = () => {
    
    const [sugg, setsugg] = useState([])
  useEffect(()=>{

    fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getmy2`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },body:JSON.stringify({search:"Academic"})
    }).then((res)=>res.json()).then((ele)=>{
  
      setsugg(ele)})
    },[])

    function sliceFiveWords(inputString) {
      // Split the string into an array of words
      var words = inputString.split(" ");
  
      // Select the first five words and join them back into a string
      var slicedString = words.slice(0, 5).join(" ");
  
      return slicedString;
  }
  return (
   <>
   

   <section className='d-flex justify-content-center align-items-center flex-column mt-2'>
   <img src="/Banner no 5.jpg"  className="banner1" alt="banner"  />
   <div className="grid-container mt-3">
   
<div className="top-con">
<h1 className='heading-text'><i class="fa-solid fa-book"></i> Academic Books</h1>
<Link to="/product/Academic"><h1><i class="fa-solid fa-fire text-warning "></i> View All</h1></Link>

</div>


<div className="bottom-con"></div>

<Swiper


navigation={true}
        slidesPerView={3}
        grid={{
          rows: 2,
        }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Grid, Pagination,Navigation]}
        className="grid-swiper"
      >
        {
            sugg.length!==0 && sugg.map((ele,i)=>{
                return  <SwiperSlide className='grid-swiper-card' key={i}><Link className='grid-anchor' to={`/productpage/${encodeURIComponent(CryptoJS.AES.encrypt(ele._id, process.env.REACT_APP_PRODUCT_KEY).toString())}`} >
                    
                    <img src={ele.bimg1} alt="" />
                    <div className='grid-price'>

                    <b>{sliceFiveWords(ele.booktitle)}</b>
                        <div className='grid-price1'>

                    <h4 className='text-danger fw-bolder  fs-5 text-decoration-line-through d-flex'><i className="fa-solid fa-indian-rupee-sign"></i>{ele.mrp}</h4>
                    &nbsp; <h4 className='text-primary fw-bolder fs-5 d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{ele.actualprice}</h4>
       &nbsp; <h4 className='text-success fw-bolder fs-5 d-flex '>{ele.discount}% OFF</h4>
                        </div>
                    </div>
      
                    
                    </Link></SwiperSlide>
            })
        }
        
     
      </Swiper>
   </div>
   </section>
   </>
  )
}

export default Gridcontainer5
