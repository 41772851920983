import React, { useEffect, useState } from 'react'
import Cardcart from './Cardcart'
import Context from '../context/Searchcontext'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import Billing from './Billing'
import ConfettiExplosion from 'react-confetti-explosion';
const Cart = () => {
  const [isExploding, setIsExploding] = React.useState(false);
  const [count, setcount] = useState(0)
  const [price,setprice]=useState(0)
  const [data, setdata] = useState([])
    const context=useContext(Context)
    const {alert,mycode, setmycode,setalert,change,discountprice, setdiscountprice}=context
const navigate=useNavigate()
const location=useLocation()  
const [code, setcode] = useState('')
const [sum, setsum] = useState(0)

useEffect(()=>{
  fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/totalprice`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
      "token":localStorage.getItem("token")
    }
  }).then((res)=>res.json()).then((ele)=>{
    
    setsum(ele.sum)
  })
},[])

const discountsubmit=()=>{

fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getcode`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json",
    "token":localStorage.getItem("token")
  },
  body:JSON.stringify({code:code})
})
.then((res)=>res.json()).then((ele)=>{

  console.log(ele)
  if(ele.success && sum>=200)
  {
    setmycode(code)
    setIsExploding(true);
    setdiscountprice(ele.max_discount)
    setalert({message:ele.message,color:"success"})
  setTimeout(()=>{
setalert(null)
setIsExploding(false);
  },5000)
  }
  else if(ele.success && sum<200){
    setalert({message:"Purchase Items Above 200 INR",color:"danger"})
    setTimeout(()=>{
      setalert(null)
      setIsExploding(false);
        },5000)
  }
  else if(sum<200  || !ele.success)
  {
    console.log("jai mata di")
    setalert({message:ele.message,color:"danger"})
    setTimeout(()=>{
      setalert(null)
      setIsExploding(false);
        },5000)
  }
})
}
useEffect(()=>{
  document.querySelector(".footer").style.display="none"

},[])
useEffect(()=>{


if(!localStorage.getItem("token"))
{
navigate("/login")
}

    },[])
    useEffect(()=>{
      localStorage.getItem("token") && fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/useritem`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "token":localStorage.getItem("token")
        }
      }).then((res)=>res.json()).then((ele)=>{setdata(ele)})
    },[alert,change])

useEffect(()=>{
  localStorage.getItem("token") && fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/countdoc`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
      "token":localStorage.getItem("token")
    }
  }).then((res)=>res.json()).then((ele)=>setcount(ele))
  
localStorage.getItem("token") && fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/totalprice`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json",
    "token":localStorage.getItem("token")
  }
}).then((res)=>res.json()).then((ele)=>{
  setprice(ele)

})

},[data])
  return (
<>
{alert && <div className={`alert alert-${alert.color} coupon-code-alert  alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong>{alert.message}</strong> 
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
<main >

    <section className='d-flex justify-content-center'>

        <div className="cart-container my-3">
          {isExploding && <ConfettiExplosion particleSize={20}   force={0.8} duration={3000} particleCount={250} width= {3000}/>}

           {data.length!=0 && data.map((ele,i)=>{
            return <Cardcart key={i} ele={ele} />
           })}
        </div>
    </section>
    <section className='d-flex justify-content-center'>
      {data && <div className="card-container2 ">
      { data.length!=0 && <div className="items d-flex items-center my-1    align-items-baseline  flex-row-reverse ">
        <button type="button" class="btn btn-secondary btn-sm mx-1 " onClick={discountsubmit}>Add</button>

        <input type="text" onChange={(e)=>setcode(e.target.value.toUpperCase())} value={code} name='coupon' placeholder='Redeem Code' className="form-control  coupon-code" id="exampleInputEmail1" aria-describedby="emailHelp"/>
            <p className='fw-bolder text-danger'>Have a Code? - &nbsp;</p>
           </div>}
           <div className="items d-flex flex-row-reverse ">
            <p className='fw-bolder text-dark'>{count.count}</p>
            <p className='fw-bolder text-danger'>Total items - &nbsp;</p>
           </div>
           <div className="items d-flex flex-row-reverse ">
            <p className='fw-bolder text-dark'><i className="fa-solid fa-indian-rupee-sign"></i> {parseFloat(price.charge).toFixed(2)}</p>
            <p className='fw-bolder text-danger'>Delivery Charge - &nbsp;</p>
           </div>
           {discountprice!=0 && <div className="items d-flex flex-row-reverse ">
            <p className='fw-bolder text-dark'><i className="fa-solid fa-indian-rupee-sign"></i> -{discountprice}</p>
            <p className='fw-bolder text-danger'>After Discount - &nbsp;</p>
           </div>}
           <div className="items d-flex flex-row-reverse ">
            <p className='fw-bolder text-dark'><i className="fa-solid fa-indian-rupee-sign"></i> {(price.sum+price.charge-discountprice).toFixed(2)}</p>
            <p className='fw-bolder text-danger'>Total Price - &nbsp;</p>
           </div>
           <div className="items d-flex flex-row-reverse ">
         <Link to="/billing">   <button type="button" className={`btn btn-warning btn-sm ${!data.length?"disabled":""}`}><i className="fa-solid fa-truck"></i> Continue</button></Link>
           </div>
      </div>}
    </section>
</main>

</>
  )
}

export default Cart