import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Cancellation = () => {
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  return (
<div className="policy2-container">

    <div className="section">
        
        <h2>CANCELLATION POLICY</h2>
        <p>
            Users (Buyers) can cancel your order anytime before dispatch, during transit we cannot cancel your order. On receiving a cancellation request, the admin will allow it and process your refund within 24 hours.
            In case you want to cancel your order, contact customer support by Whatsapp on <span className="highlight">+917003810616</span> or mail us at <span className="highlight">Booksindya2024@gmail.com</span> within 24 hours of order or before shipment.
            We will try our best to help you with your concern, but since we try to get the orders to you expeditiously, it might not be possible to cancel your order if it has already been shipped.
            The delivery time purely depends on many factors like distance, location, availability, etc. Generally the logistic partners take 4-6 Business Days to deliver the products from our warehouse to the destination pin-codes throughout India, But if the destination pin-code is located at a remote location then it may take more time.
            If the item shows delivered on the official website of the Indian post service but didn't receive the package, In such cases, users need to contact us through WhatsApp on <span className="highlight">+917003810616</span> or mail us at <span className="highlight">Booksindya2024@gmail.com</span> within 24 hours of delivery.
        </p>
    </div>



    </div>
  )
}

export default Cancellation