
import React, { useEffect, useState} from 'react'
import CryptoJS from 'crypto-js';
import ReactImageMagnify from 'react-image-magnify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import Context from './context/Searchcontext';

import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import { Swiper, SwiperSlide } from 'swiper/react';



import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';




import { Autoplay, Pagination , Navigation,FreeMode } from 'swiper/modules';



// import required modules

import Policy from './Minorcomponents/Policy';


const Itempage = () => {
  const [sugg, setsugg] = useState([])
const navigate=useNavigate()
  const context=useContext(Context)
  const {alert,setalert}=context
  const [add, setadd] = useState(0)
  const [image, setimage] = useState("")
  let {id}=useParams()
const [item, setitem] = useState([])
const [mode, setmode] = useState("Standard: Mode")



function sliceFiveWords(inputString) {
  // Split the string into an array of words
  var words = inputString.split(" ");

  // Select the first five words and join them back into a string
  var slicedString = words.slice(0, 5).join(" ");

  return slicedString;
}
useEffect(()=>{
  const decodedURL = decodeURIComponent(id);
  const bytes = CryptoJS.AES.decrypt(decodedURL, process.env.REACT_APP_PRODUCT_KEY);

  window.scrollTo({
    top:0,
    behavior:'smooth'
  })
fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getitem`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json"
  },body:JSON.stringify({_id:bytes.toString(CryptoJS.enc.Utf8)})
}).then((res)=>res.json()).then((ele)=>{
  
  if(ele.success==false)
  {
    navigate("*")
  }
  setitem(ele)
})
},[id])



useEffect(()=>{

  fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getmy2`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },body:JSON.stringify({search:item.category})
  }).then((res)=>res.json()).then((ele)=>{

    setsugg(ele)})
  },[item])
useEffect(()=>{

},[alert])

const handlechange=async(e)=>{
setmode(e.target.value)

}
  const desclick=(e)=>{
   
      setadd(add-1)
    
  }
  const inclick=(e)=>{
    if(add<5 && add<item.stock)
    {
      setadd(add+1)
    }
    else{
      setalert({message:`Only ${item.stock} stock are left`,color:"warning" })
      setTimeout(()=>{
        setalert(null)
        },2000)
    }
  
  }

  const [rating, setrating] = useState({review:"",rating:""})


const ratingchange=async(e)=>{
e.preventDefault()
setrating({...rating,[e.target.name]:e.target.value})
console.log(rating)
}


const ratingsubmit=async(e)=>{
  e.preventDefault()
  if(!localStorage.getItem("token"))
  {
    navigate("/")
    return
  }
  if(rating.rating.length==0 ||rating.review.length<6){
    setalert({message:"Invalid Credentials",color:"danger"})
    setTimeout(()=>{
      setalert(null)
      },2000)
  return
  }
  const decodedURL = decodeURIComponent(id);
  const bytes = CryptoJS.AES.decrypt(decodedURL, process.env.REACT_APP_PRODUCT_KEY);


  fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/giverating`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
      "token":localStorage.getItem("token")
    },body:JSON.stringify({bookid:bytes.toString(CryptoJS.enc.Utf8),rating:rating.rating,review:rating.review})
  }).then((res)=>res.json()).then((ele)=>{
    if(ele.success)
    {
      setalert({message:ele.message,color:ele.color})
      setTimeout(()=>{
        setalert(null)
        window.location.reload()
        },2000)
    }
    else{
      setalert({message:"Something Went Wrong",color:"danger"})
      setTimeout(()=>{
        setalert(null)
        },2000)
    }
    
  })
}





  const handlesubmit=async(e)=>{
    e.preventDefault()
    if(!localStorage.getItem("token"))
    {
      navigate("/login")
    }
    fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/addtocart`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
      },body:JSON.stringify({id:item._id,booktitle:item.booktitle,imgtext1:item.bimg1,mrp:item.mrp,discount:item.discount,binding:item.binding,publication:item.publication,actualprice:item.actualprice,quantity:add,mode:mode})
    }).then((res)=>res.json()).then((ele)=>{setalert(ele)
      setTimeout(()=>{
        setalert(null)
        },3000)
    })
  }

const [rate, setrate] = useState({avg:""})
useEffect(()=>{
  const decodedURL = decodeURIComponent(id);
  const bytes = CryptoJS.AES.decrypt(decodedURL, process.env.REACT_APP_PRODUCT_KEY);

fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getrating`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json",
    
  },body:JSON.stringify({bookid:bytes.toString(CryptoJS.enc.Utf8)})
}).then((res)=>res.json()).then((ele)=>{setrate({avg:ele.avg})})
},[])

useEffect(()=>{

  },[alert])
  
const wishlistsubmit=async(e)=>{

  e.preventDefault()
  if(!localStorage.getItem("token"))
  {
    navigate("/login")
    return
  }
  fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/addtowishlist`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
      "token":localStorage.getItem("token")
    },body:JSON.stringify({bookid:item._id})
  }).then((res)=>res.json()).then((ele)=>{
    setalert(ele)
    setTimeout(()=>{
      setalert(null)
      },2000)
  })
}
  return (
<>
{alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
   {alert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
<main>
    <section  className='d-flex justify-content-center' >

<div className=' product-add-page'>

    <div className="Left-Items">

    <div className="item-image">
    <div className="fluid__image-container" style={{width:"300px"}}>
                    <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: image || `${item.bimg1}`,
                           
                            
                        },
                        largeImage: {
                            src: image || `${item.bimg1}`,
                            width: 1200,
                            height: 1800
                        }
                    }} />
                </div>
    </div>
    <div className="select-image">
        <img src={item.bimg1} onClick={()=>{setimage(item.bimg1)}} alt="img1" />
        <img src={item.bimg2} onClick={()=>{setimage(item.bimg2)}}  alt="img2" />
        <img src={item.bimg3} onClick={()=>{setimage(item.bimg3)}} alt="img3" />
        <img src={item.bimg4} onClick={()=>{setimage(item.bimg4)}} alt="img3" />
     
     
     
    </div>
  
   {rate.avg && <div className='d-flex align-items-center flex-column mt-3'>

    <Typography component="legend">Overrall rating</Typography>
<Rating name="no-value" readOnly size='large' value={rate.avg}  />
    </div>}

    </div>
    <div className="Right-Items">
        <h3>{item.booktitle}</h3>
  <h3><span className='actual-price'><i className="fa-solid fa-indian-rupee-sign"></i>{item.actualprice}</span> <span className='Real-price'><i className="fa-solid fa-indian-rupee-sign"></i>{item.mrp}</span> <span className='discount-percentage'>({item.discount}% off)</span> </h3>
  <p>{item.bookdescription?item.bookdescription.slice(0,150):""}...<b className='text-primary pointer' onClick={()=>document.documentElement.scrollBy(0,1300)}>Read more</b></p>

  <div className="shipping-cost">
    <p>Shipping Cost</p>
<select name="deliverymode" id="deliverymode" onChange={handlechange}>
    <option value="Standard: Mode">Standard: Mode</option>
</select>
<img src="/guarantee2.png" alt="1"/>

  </div>

  <div className="items-btn">
    <div className='items-first-child'>

    <p>Availability:</p>
    <span className={!item.stock?"bg-danger text-light":"bg-success text-light"}>{!item.stock?"OUT-OF-STOCK":"IN-STOCK"}</span>
    </div>

   {item.stock? <div className="items-second-child">
        <div>

        <button className={`btn btn-secondary btn-sm ${!add? "disabled":""}` } onClick={desclick}><i className="fa-solid fa-minus"></i></button>
   <input type="text" id="add-to-cart" disabled value={add} />
        <button className={`btn btn-secondary btn-sm ${add==5? "disabled":""}`} onClick={inclick} ><i className="fa-solid fa-plus"></i></button>
        </div>
        <button className={`btn btn-danger ${!add? "disabled":""}`} onClick={handlesubmit}>ADD TO CART</button>
       

   
    </div>:""}
  
        <form onSubmit={wishlistsubmit}>

<button type="submit"  className='btn btn-dark'><i className="fa-solid fa-heart text-danger "></i> Wishlist</button>
        </form>
 

   
  </div>
  <div className="items-offers d-flex flex-column row-gap-2">
<span><i className="fa-solid fa-tag mt-3 text-success"></i> <b>Easy Return Policy</b>: 10 Days <a href="#" className='text-decoration-none'>Read More</a></span>
<span><i className="fa-solid fa-tag text-success"></i> <b>Best Buy Offer</b>: Up to 60% Off <a href="#" className='text-decoration-none'>View More</a></span>
<span><i className="fa-solid fa-tag text-success"></i> <b>Delivery Method</b>: All india Delivery <a href="#" className='text-decoration-none'>TC</a></span>
<span><i className="fa-solid fa-tag text-success"></i> <b>Helpline</b>: 24*7 Active <a href="#" className='text-decoration-none'>Read More</a></span>
<div className='d-flex align-items-center column-gap-2'>
<i className="fa-solid fa-share-nodes text-primary"></i>
<span>Share in:</span> 
<a href="https://www.facebook.com/profile.php?id=61553364841273&mibextid=gjUFcU"><button className='btn btn-sm btn-primary text-light' ><i className="fa-brands fa-facebook"></i></button></a>
<button className='btn btn-sm btn-primary text-light'><i className="fa-brands fa-twitter"></i></button>
<a href="https://chat.whatsapp.com"><button className='btn btn-sm btn-success text-light'><i className="fa-brands fa-whatsapp"></i></button></a>
<button className='btn btn-sm btn-primary text-light'><i className="fa-brands fa-telegram"></i></button>
</div>

</div>



</div>

</div>


    </section>
<section className='d-flex justify-content-center align-items-center flex-column mb-3 '>
  <div className="item-page-brief swiping-card bg-light" >
  <h3 className='align-self-start mb-3'>More Interesting Books Here</h3>

  <Swiper
        slidesPerView="auto"
        spaceBetween={5}
     navigation={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination,Autoplay,Navigation]}
       
        >



{
  sugg  && sugg.map((ele)=>{
    
    return <SwiperSlide key={ele._id}>
    <Link className='text-decoration-none text-dark d-flex flex-column align-items-center justify-content-center' to={`/productpage/${encodeURIComponent(CryptoJS.AES.encrypt(ele._id, process.env.REACT_APP_PRODUCT_KEY).toString())}`}>

    <img src={ele.bimg1} alt="bimg"  />
    <p className='fw-bolder fs-6'>{sliceFiveWords(ele.booktitle)}</p>
    </Link>
    <div className='d-flex'>

    <h4 className='text-danger fw-bolder  fs-6 text-decoration-line-through d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{ele.mrp}</h4>
    &nbsp; <h4 className='text-primary fw-bolder fs-6 d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{ele.actualprice}</h4>
       &nbsp; <h4 className='text-success fw-bolder fs-6 d-flex '>{ele.discount}% OFF</h4>
    </div>
  </SwiperSlide>
})
}

       
      </Swiper>
        </div>

        <div className=" product-detail my-3">
         <h3 >Product Detail</h3>
         <table>
          <tbody>

          <tr>
            <th>Publisher</th>
            <td>{item.publication}</td>
          </tr>
          <tr>
            <th>Auther,Edition</th>
            <td>{item.auther}</td>
          </tr>
          <tr>
            <th>Binding Type</th>
            <td>{item.binding}</td>
          </tr>
          <tr>
            <th>No. of Pages</th>
            <td>{item.pages}</td>
          </tr>
          <tr>
            <th>Weight</th>
            <td>{item.weight} gm</td>
          </tr>
          <tr>
            <th>ISBN</th>
            <td>{item.isbn}</td>
          </tr>
          </tbody>
         </table>
         <h3 className='my-3'>Product Description</h3>
         {item.bookdescription}
         <h3 className='my-3'>SHIPPING POLICY </h3>
         <br />
        <ul className='policy'>
          <li>Shipping will be initiated within 24 hours of order receiving. Normal delivery time will be 5-6 days, for local it will be 2-3 days.</li>
          <li>For successfully orders, Customer needs to fill the correct address in below format at the time of placing an order or checkout. While entering the address users need to take special attention to fill proper address i.e. Full Name, C/O (Parents / Guardian Name), House No, Landmark, Mobile Number and others address related details etc. </li>
          <li> “Booksindya” assure users to deliver the ordered product from Monday to Saturday in-between 7AM - 9PM throughout all pin-codes in India at best & challenging price. </li>
          <li>You will receive a notification from us via email or What’s app with the tracking details once your books have been shipped. </li>
          <li>You can then click on the ‘Track your order’ link  provided at  the website (Booksindya.in) to track your order until it reaches you.</li>
          <li>For your order to reach you in the fastest time and in good condition, we only ship through Indian post service.</li>
        <li>We make our best efforts to ship each item in your order within 1 to 2 working days of the order. However in some cases, we may take longer, up to 3  to 4 working days, to ship the order if a certain product is out of stock or will be available soon.</li>
        <li>“Booksindya” generally process all orders separately as per the Order Ids, But sometimes if we received any multiple orders from the same Users Email Accounts with multiple order Ids but the delivery address are same. In that cases “Booksindya” merge the order Ids & ship it in one box / packaging which will help the users to collect it in single package instead of collecting multiple packages. </li>
        <li>Shipping Rates:<ul><li> 
        <li>Shipping Rates are calculated on the total weight of all books on the cart.</li>
<li>We do not charge any excess shipping rate, the shipping charges charged by the courier companies is forwarded to you.</li>
</li></ul></li>
<li>No Cash on Delivery(COD) payment service facility are available in our website.</li>
        </ul>


        <h3 className='my-3'>DELIVERY POLICY </h3>
         <br />
        <ul className='policy'>
          <li>Deliveries are made all week except national holidays through Indian post services. We try our best to ensure that you receive your product within the stipulated time, however, if you do not receive your package within the specified time, you are requested to contact customer support by Whatsapp on +917003810616 or mail us at in Booksindya2024@gmail.com  within 2 working day of delivery.</li>
          <li>If you are not present at the time of delivery, “Booksindya” will assume that any other family member at the provided address has been authorized to accept the orders on your behalf. In case that this isn’t possible or there is no one present at the time of delivery, we will not deliver the product and contact you to set up an alternate delivery time.</li>
          <li>Please note that our delivery personnel will deliver your products at your front door only.</li>
          <li>The delivery time is purely depends on many factors like distance, location, availability, etc. Generally the logistic partners takes 4-6 Business Days to deliver the products from our warehouse to the destination pin-codes throughout India, But if the destination pin-code is located at remote location then it may takes more time. </li>
          <li>If the item shows delivered in official website of the Indian post service but didn't received the package, In such cases users need to contact us through What’s app on +917003810616 or mail us at Booksindya2024@gmail.com  within 24 hour of delivery.</li>
          
        </ul>



        
        <h3 className='my-3'>CANCELLATION POLICY </h3>
         <br />
        <ul className='policy'>
          <li>Users (Buyers)  can cancel your order anytime before dispatch, during transit we cannot cancel your order. On receiving a cancellation request, the admin will allow it and process your refund within 24 hour.</li>
          <li>In case you want to cancel your order, contact customer support by Whatsapp on +917003810616 or mail us at  Booksindya2024@gmail.com  within 24 hour of order or before shipment.</li>
          <li>We will try our best to help you with your concern, but since we try to get the orders to you expeditiously, it might not be possible to cancel your order if it has already been shipped.</li>
          <li>The delivery time is purely depends on many factors like distance, location, availability, etc. Generally the logistic partners takes 4-6 Business Days to deliver the products from our warehouse to the destination pin-codes throughout India, But if the destination pin-code is located at remote location then it may takes more time. </li>
          <li>If the item shows delivered in official website of the Indian post service but didn't received the package, In such cases users need to contact us through What’s app on +917003810616 or mail us at Booksindya2024@gmail.com  within 24 hour of delivery.</li>
          
        </ul>



        <h3 className='my-3'>RETURN POLICY </h3>
         <br />
        <ul className='policy'>
          <li>To return products that are damaged in transit or in case a wrong product is delivered, please contact customer support by by Whatsapp on +917003810616 or mail us at  Booksindya2024@gmail.com    within 3 working day of delivery. Any return requests later than that will not be entertained.</li>
          <li>You have 3 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. Your item must be in the original packaging. Your item needs to have the receipt or proof of purchase.</li>
          <li>No Problem, We accept returns in these cases within 3 days of date of delivery. We do ask that you please understand that shipping fees will not be reimbursed.</li>
          <li>Once the return is received the refund will be processed in 3 days and you can expect it in your same payment method within 7 days.</li>
          <li><br />
          Please send the return to: 
Kiran Sinha <br />
C/O Ravi Bhushan Srivastava <br />
Chiragora, Samshan Road, <br />
Behind Mission of Knowledge school, Hirapur, <br />
Dhanbad-826001. <br />
Mobile no-7003810616 <br />

            </li>
            <li>“Booksindya” cannot provide any return for OLD/USED book purchased from our websites.</li>
          
        </ul>



        
        <h3 className='my-3'>REPLACEMENT POLICY </h3>
         <br />
        <ul className='policy'>
          <li>Before dispatch, we check each of the products thoroughly so that you get the products in the condition you have ordered. However, we cannot rule out the possibility of damage to the product in transit (we try to minimize the same by packing the books properly and padding them with cardboard). </li>
        <li>Therefore, any product you have received which either has a manufacturing defect is damaged in transit or is not up to the mark in terms of quality is eligible for replacement. </li>
        <li>Once you have received a defective product, you can mail us at  Booksindya2024@gmail.com or WhatsApp us on +917003810616   within 48 hours of receipt of the product. Do mention the reason for such request and also provide us photos of the defect for quick redressal. </li>
        <li>We will inspect your request and approve the replacement request, and detailed information shall be provided to you regarding the pickup and reshipment of your product . We promise that it will involve minimum hassle and will not be troublesome.</li>
        <li>Once the return is received from customer/buyer , We will ship you another copy of the same product within 24 hours.</li>
        <li>“Booksindya” cannot provide any replacement for OLD/USED book purchased from our websites.</li>
        </ul>


         
        <h3 className='my-3'>REFUND POLICY </h3>
         <br />
        <ul className='policy'>
         <li>Booksindya provides refund only in case of: <ol>
          <li>Damaged or Faulty Product(s)</li>
          <li>Wrong Product(s) delivered which are not as per your order</li>
          <li>Cancellation of order before dispatch</li>
          </ol>
          </li>
          <li>Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item. </li>
         
          <li>Once the refund request is approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 3  days depending on the time taken by our payment gateway providers and banking channels.</li>
        <li>For orders placed through gift certificates or special coupon discounts, refund would be provided in form of a new gift certificate or store credit for the same value.</li>
        <li>Please note that postage/courier charges are not refundable.</li>
        <li>“Booksindya” reserves the right to refuse any refund wherein it is evident that the product has been used.</li>
        <li>“Booksindya” cannot provide any refund for OLD/USED book purchased from our websites.</li>
        </ul>
       
        </div>

        
</section>
{localStorage.getItem("token") &&

<section className='d-flex justify-content-center'>
  <div className="ratingcontainer ">

<div className="accordion accordion-flush " id="accordionFlushExample">
  <div className="accordion-item ">
    <h2 className="accordion-header ">
      <button className="accordion-button fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        GIVE A REVIEW
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
        <form onSubmit={ratingsubmit}>

        <div className='d-flex w-50 justify-content-between'>

      <b>Give Rating this product</b>
<Rating  name="rating" onChange={ratingchange}  size='large'  />
        </div>
<div>
  <label htmlFor="review">Put your Review content</label>
  <textarea name="review" id="review" onChange={ratingchange} className='w-100' cols="  100" rows="3"></textarea>
</div>
<div>
  <button type='submit' className='btn btn-warning btn-sm mt-3'>Submit</button>
</div>
        </form>
      </div>
    </div>
  </div>

  </div>

</div>
</section>
}
<Policy/>


</main>
</>
  )
}

export default Itempage
