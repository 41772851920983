import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Refund = () => {
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  return (
    <div className="policy2-container">



 
    <div className="section">

        
        <h2>REFUND POLICY</h2>
        <p>
            Booksindya provides a refund only in case of:
            <ul>
                <li>Damaged or Faulty Product(s)</li>
                <li>Wrong Product(s) delivered which are not as per your order</li>
                <li>Cancellation of order before dispatch</li>
            </ul>
            Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you of the status of your refund after inspecting the item.
            Once the refund request is approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 3 days depending on the time taken by our payment gateway providers and banking channels.
            For orders placed through gift certificates or special coupon discounts, a refund would be provided in the form of a new gift certificate or store credit for the same value.
            Please note that postage/courier charges are not refundable.
            “Booksindya” reserves the right to refuse any refund wherein it is evident that the product has been used.
            “Booksindya” cannot provide any refund for OLD/USED books purchased from our websites.
        </p>
        
    </div>
    </div>
  )
}

export default Refund