// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDx8FreNKXNH3Kdoy9XTjrSeDoLwOJ0n6A",
  authDomain: "booksindya-6c63c.firebaseapp.com",
  projectId: "booksindya-6c63c",
  storageBucket: "booksindya-6c63c.appspot.com",
  messagingSenderId: "553555937160",
  appId: "1:553555937160:web:27edba5426eb2d6d4f9c3d",
  measurementId: "G-KNH8SRDWP9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app