import React, { useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";


import Itemcards from "./Minorcomponents/Itemcards";
import Context from "./context/Searchcontext";
import { useLocation, useParams } from "react-router-dom";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

const Publication = () => {
  const [length, setlength] = useState(1)
  const [hasMore, setHasMore] = useState(true);
  let {search,page, setPage,items, setItems}=useContext(Context)
let {id}=useParams()
let location=useLocation()
useEffect(()=>{
  document.documentElement.scrollTo({
    top:0,
    behavior:"smooth"
  })
  document.querySelector(".footer").style.display="none"
},[location])

  useEffect(() => {
    setItems([])
    setPage(0)
    setHasMore(true)
    fetchData();
  }, [id,location]);



  const fetchData = () => {

    const url = `${process.env.REACT_APP_FETCH_API_ADDRESS}/publisher-books`;

    fetch(url,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },body:JSON.stringify({search:id,skip:page})
    })
      .then(response => response.json())
      .then(data => {
        
        let combinedArray = items.concat(data.data);
        let uniqueSet =[...new Set(combinedArray)];
        
        setItems(uniqueSet);
        setPage(prevPage => prevPage + 12); 
        setlength(data.data.length)
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const fetchMoreData = () => {
    fetchData();
    
  };

  useEffect(()=>{
if(length===0){
  setHasMore(false)
}
  },[page])

  return (
    <div className="mt-4">

    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<img src="/Ripple-1.1s-64px.svg"/>}
      endMessage={<p>No more items to load</p>}
    >
      {items.map(ele => (
      <Itemcards   ele={ele}/>
      ))}
    </InfiniteScroll>
  </div>
  )
}

export default Publication