import React, { useState } from 'react'

const Reqform = () => {
    const [credentials, setcredentials] = useState({title:"",auther:"",publisher:"",courses:"",pno:"",email:""})
    const [alert, setalert] = useState(null)
    const handlechange=(e)=>{
e.preventDefault()

setcredentials({...credentials,[e.target.name]:e.target.value})
    }

    const handlesubmit=(e)=>{
        e.preventDefault()
        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/reqforform`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },body:JSON.stringify({title:credentials.title,auther:credentials.auther,publisher:credentials.publisher,courses:credentials.courses,pno:credentials.pno,email:credentials.email})
        }).then((res)=>res.json()).then((ele)=>{
            
                setalert({message:ele.message,color:ele.color})
            setTimeout(()=>{
                setalert(null)
            },2000)
        })

    }
  return (
    <>
    {alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong><i className="fa-regular fa-thumbs-up"></i></strong> {alert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    <div className="container req-form">

    <h1>Request A Book</h1>
    <form onSubmit={handlesubmit}>
        <div>

    <label htmlFor="bookname">Books Name/Title</label>
    <input type="text" name="title" onChange={handlechange} id="title" />
        </div>
        <div>

    <label htmlFor="author">Author</label>
    <input type="text" id='auther' onChange={handlechange} name='auther' />
        </div>
        <div>

    <label htmlFor="publisher">Publisher</label>
    <input type="text" name="publisher" onChange={handlechange} id="publisher" />
        </div>

        <div>
            <label htmlFor="courses">Courses/Exam</label>
            <input type="text" id='courses' onChange={handlechange} name='courses' />
        </div>

        <div>
            <label htmlFor="contact">Contact No.</label>
            <input type="tel" id='pno' onChange={handlechange} name='pno' />
        </div>
        <div>
            <label htmlFor="email">Email Id</label>
            <input type="email" id='email' onChange={handlechange} name='email' />
        </div>
        <div>
            <button type="submit" className='btn btn-warning btn-sm'>Submit</button>
        </div>

    </form>


    </div>
    
    
    </>
  )
}

export default Reqform