import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import app from './Firebase'
import { getAuth, RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import Loading from './Loading';
import {phone} from 'phone';
import * as EmailValidator from 'email-validator';
import schema from './validate';
const Forget = () => {
  const [loading, setloading] = useState(false)
  
  const navigate=useNavigate()
  useEffect(()=>{
if(localStorage.getItem("token"))
{
  navigate("/")
}
  },[])
  const handlechange=(e)=>{
        setcredntials({...credntials,[e.target.name]:e.target.value})
      }
      const [credntials, setcredntials] = useState({pno:"",npassword:"",cpassword:"",otp:""})
      const [otp,setotp]=useState(null)
      const [forget, setforget] = useState({type:1})
      const [alert, setalert] = useState(null)

      useEffect(()=>{
        setTimeout(()=>{
          setalert(null)
          },3000)
      },[alert])







      const startTimer=()=> {
        let timer;
        let duration = 120; 
      let timerDisplay = document.getElementById("otpTimer");
      
      
      
      let minutes, seconds;
      timer = setInterval(function() {
        minutes = parseInt(duration / 60, 10);
        seconds = parseInt(duration % 60, 10);
      
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
      
        timerDisplay.textContent = minutes + ":" + seconds;
      
        if (--duration < 0) {
          clearInterval(timer);
          timerDisplay.textContent = "00:00";
      
          duration = 120; 
      setalert({message:"Session Timeout",color:"danger"})
      setTimeout(()=>{
      window.location.reload()
      },3000)
        }
      }, 1000);
      }
           
const checkacc=async(e)=>{
    e.preventDefault()
    setloading(true)
const response=await fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/check`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json"
    },body:JSON.stringify({pno:credntials.pno})
})

const json=await response.json()
if(json.success)
{
   onSignInSubmit()
}
else
{
setalert({color:"danger",message:"Account doesn't Exist"})
setloading(false)

}

}
const captchaverify=()=>{
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit();
      }
    });
}


const onSignInSubmit=()=>{
    
    captchaverify()
    const phoneNumber ="+91"+credntials.pno
    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth();
signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {

      window.confirmationResult = confirmationResult;
      setalert({color:"success",message:"Otp Successfully Send To Your Mobile"})
      setloading(false)
        setforget({type:2})
        setTimeout(()=>{

          startTimer()
        },1000)
    }).catch((error) => {

setloading(false)

    });
}

const submitOtp=(e)=>{
    e.preventDefault()
    setloading(true)
    const code = credntials.otp
    window.confirmationResult.confirm(code).then((result) => {

  const user = result.user;
 setloading(false)
  setforget({type:3})
  
}).catch((error) => {
setloading(false)
setalert({color:"danger",message:"Invalid Otp"})

});
}



const updatesubmit=async(e)=>{
  e.preventDefault()
  setloading(true)
  const Ind=phone(`${credntials.pno}`, {country: 'IND'});
  if(!Ind.isValid)
  {
  setloading(false)

    setalert({message:"Only Indian Number are allowed",color:"danger"})
    return 
  }
  if(!schema.validate(credntials.npassword) || !schema.validate(credntials.cpassword))
{
  setloading(false)
  setalert({message:"Please Enter the valid Password",color:"danger"})
  return 
}
  if(credntials.npassword!==credntials.cpassword)
  {
  setloading(false)

    setloading(false)
    setalert({color:"danger",message:"Invalid Credentials"})
    return
  }
  const response=await fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/forgetpassword`,{
    method:"PUT",
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify({pno:credntials.pno,npassword:credntials.npassword,cpassword:credntials.cpassword})
  })
const json=await response.json()
if(json.success)
{
  setalert({color:"success",message:json.message})
  setTimeout(()=>{
navigate("/")
  },2000)
}
else{
  setalert({color:"danger",message:"Invalid credentials"})

  setloading(false)
}

}


  return (
    <div>
        {alert && <div className={`alert alert-${alert.color} alert-dismissible fade show text-center position-sticky bottom-50`} style={{position:"sticky",top:"0"}} role="alert">
  <strong><i className="fa-regular fa-thumbs-down"></i> {alert.message}</strong> 
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    <div id="sign-in-button"></div>
    {loading && <Loading/>}
    {!loading && 
<div className='container d-flex justify-content-center my-5'>
       {forget.type==1 && <form onSubmit={checkacc}>

    <div className="signup-form">
    <Link to="/"> <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" /></Link>
     <h3>Forget Password</h3>
     <div className="con1">
       <label htmlFor="pno">Mobile Number</label>
       <input type="tel" name="pno" id="pno" placeholder='Only Indian Mobile Number' minLength={10} maxLength={10} onChange={handlechange} />
   
     </div>

     <div className="con2 d-flex flex-column row-gap-3">
       <button type="submit" className="btn btn-warning">Continue</button>
     </div>
     <div className="con2 d-flex column-gap-2 ">
       <p>
 No I Have No Account </p>
       <Link to="/signup" className='text-decoration-none' >Sign Up <i className="fa-solid fa-caret-right" style={{color: "#1f33e4"}}></i></Link>
     </div>

    </div>
    </form>}

    
        
       {forget.type==3 && <form onSubmit={updatesubmit}>

        <div className="signup-form">
        <Link to="/"> <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" /></Link>
         <h3>Forget Password</h3>
         <div className="con1">
      <label htmlFor="password">New Password</label>
      <input type="password" name="npassword" id="npassword" placeholder='At Least 6 characters' onChange={handlechange} />
  
    </div>

    <div className="con1 con2">
      <label htmlFor="cpassword">Confirm Password</label>
      <input type="password" name="cpassword"  id="cpassword" placeholder='Confirm Password' onChange={handlechange} />
      <p> <i className="fa-solid fa-info" style={{ color:"#2d03ed"}}></i> Passwords must be at least 6 characters. </p>
  
    </div>
         <div className="con2 d-flex flex-column row-gap-3">
           <button type="submit" className="btn btn-warning">Continue</button>
         </div>
         <div className="con2 d-flex column-gap-2 ">
           <p>
     No I Have No Account </p>
           <Link to="/signup" className='text-decoration-none' >Sign Up <i className="fa-solid fa-caret-right" style={{color: "#1f33e4"}}></i></Link>
         </div>
    
        </div>
        </form>
    }

{ forget.type==2 && <form onSubmit={submitOtp} >

<div className="signup-form">
 <img src="/WhatsApp Image 2023-08-09 at 10.27.58.jpg" width="200px" alt="1" />
 <h3>Enter the OTP </h3>
 <div className="con1">
      <label htmlFor="pno">Enter OTP</label>
      <input type="tel" name="otp" id="otp" placeholder='eg. 123456' maxLength={6} minLength={6} onChange={handlechange}/>
  
    </div>
 <div className="con2 d-flex flex-column row-gap-3">
 <div className='d-flex w-100 gap-3 fs-6 justify-content-center align-items-center'>
    <b>Session timeout In</b>
 <div id="otpTimer">00:00</div>
  </div>
   <button type="submit" className="btn btn-warning" >Submit</button>

 </div>



</div>
</form>}
</div>}
    </div>
  )
}

export default Forget
