import React, { useEffect } from 'react';

const Testimonial = () => {
  useEffect(()=>{
    const testimonialsContainer = document.querySelector(".testimonials-container");
    const testimonial = document.querySelector(".testimonial");
    const userImage = document.querySelector(".user-image");
    const username = document.querySelector(".username");
    
    
    const testimonials = [
      {
        name: "Rupal Srivastava",
        photo:
          "/images/Rupal Srivastava.jpg",
        text: "A best place to buy a book at unbeatable price. I was searching for a book for many years and I was happy that I could find it here. Thank you so much booksindya.",
      },
      {
        name: "Raesh Ansari",
        photo:
          "/images/Raesh Ansari.jpg",
        text: "Had an amazing experience with booksindya. The customer service was outstanding, and the product was exactly what I was looking for. I was so pleased with the service I received from these website. ",
      },
      {
        name: "Shivangi Shukla",
        photo:
          "/images/Shivangi Shukla.jpg",
        text: "I recently visited this website and I was really impressed by the wide collection of books available here. The website is well-stocked with books of all genres and the customer service is very helpful and knowledgeable. ",
      },
      {
        name: "Suraj Chaturvedi",
        photo: "/images/Suraj Chaturvedi.jpg",
        text: "I visited “Booksindya” website last week and I was surprised because I think I would not get my book here but they have very good and latest collection of books. I found my book which I needed and the price is affordable. They also have rare collection of Indian and Western author's. The customer service of this website is also very humble.  ",
      },
      {
        name: "Kosik Rajput",
        photo: "/images/Kausik Rajput.jpg",
        text: "I recently discovered “Booksindya” website from my facebook account and what I found!  Its an absolute paradise for book worm like me. That range of books they have is seriously impressive, covering all sort of genres you can think of. What really stood out to me was how helpful and friendly customer service was. They knew their book collection and went out their way to assist me in finding specific titles.  are readily available  Timely Delivery and offline reading is an added advantage of kopykitab app along with friendly user interface. As far as the price is concerned there is huge discount on books. I personally recommend this app ",
      },
      {
        name: "Rohit Benjamin",
        photo:
          "/images/Rohit Benjamin.jpg",
        text: "“Booksindya” is the best place to buy spiritual books at very affordable prices, I have taken Bhagavad Gita book from this store for Rs.250/- only which is very minimal cost when compared with other online book store. The book quality and size is also very good.",
      }
    ];
    
    let idx = 0;
    
    function updateTestimonial() {
      const { name, photo, text } = testimonials[idx];
    
      testimonial.innerHTML = text;
      userImage.src = photo;
      username.innerHTML = name;
    
      idx++;
    
      if (idx > testimonials.length - 1) {
        idx = 0;
      }
    }
    
    setInterval(updateTestimonial, 10000);
     
  },[])
  return (
    <>
<div class="main">
        <h2>Student Testimonial</h2>
        <div class="testimonial-container">
            <div class="progress-bar"></div>
            <div class="fas fa-quote-right fa-quote"></div>
            <div class="fas fa-quote-left fa-quote"></div>
            <p class="testimonial">
                I bought an ebook from here whose price is much less than the same book that is available in
                market.
                So i think that
                online book saves both money and time as compared to offline books.
            </p>
            <div class="user">
                <img src="https://static.kopykitab.com/image/cache/data/Rahul%20Tiwari-110x110.jpeg" alt="user"
                    class="user-image" />
                <div class="user-details">
                    <h4 class="username">Rahul Tiwari</h4>
                </div>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default Testimonial