import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js';
const Offer = () => {
    const [week, setweek] = useState([])
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getmydiscount`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },body:JSON.stringify({discount:40})
        }).then((res)=>res.json()).then((ele)=>{setweek(ele)})
        },[])
        
    const [time, settime] = useState({days:"",hours:"",min:"",sec:""})
    useEffect(()=>{




        document.getElementById("left-btn").addEventListener("click",()=>{
    
            let x=document.getElementById("magic")
            let style=getComputedStyle(x)
            
            if(style.display==="flex"){
            
          
                document.getElementById("magic").scrollBy(-300,0)
          
            }
            else{
             document.getElementById("magic").scrollBy(0,-300)
          
            }
          })
          
          document.getElementById("right-btn").addEventListener("click",()=>{
              
             let x=document.getElementById("magic")
             let style=getComputedStyle(x)
             
             if(style.display==="flex"){
          
          
           
                 document.getElementById("magic").scrollBy(300,0)
          
           
             }
             else{
              document.getElementById("magic").scrollBy(0,x.clientHeight)
           
             }
           })
    },[])


useEffect(()=>{
    var countDownDate = new Date("dec 31, 2024 15:37:25").getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {
    
      // Get today's date and time
      var now = new Date().getTime();
    
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
    
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
      // Display the result in the element with id="demo"
      settime({days:days,hours:hours,min:minutes,sec:seconds})
    //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
    //   + minutes + "m " + seconds + "s ";
    
      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
},[])
function sliceFiveWords(inputString) {
    // Split the string into an array of words
    var words = inputString.split(" ");

    // Select the first five words and join them back into a string
    var slicedString = words.slice(0, 5).join(" ");

    return slicedString;
}
  return (
   <>
   <section className='d-flex justify-content-center my-3'>

   <div className="time-card">
            <div className="con1 ">
               <h1 className='fw-bolder '>Grab Your Deal</h1>
            <div className='d-flex gap-2 fs-5' >
               <div>
                <input type="text" className='coundown text-dark' value={time.days} disabled />
                <p >Days</p> 
            </div> :

            <div>
                <input type="text" className='coundown text-dark' value={time.hours} disabled /> 
                <p>Hour</p> 
            </div> :
            <div>
                <input type="text" className='coundown text-dark' value={time.min} disabled /> 
                <p >Min</p> 
            </div> :
            <div>
                <input type="text" className='coundown text-dark' value={time.sec} disabled />
                <p >Sec</p>  
            </div>
            </div>
            <p className='text-center   offer-des'>Limited Time,<br />Limitless Offers! <br /> 🙏 On Customer Demand 🙏</p>
            </div>
          
            <div className="right-con2">

                <div className="con2" id="magic">
                    {week && week.map((ele)=>{
                        
                        return  <Link key={ele._id} className='text-decoration-none text-dark'  to={`/productpage/${encodeURIComponent(CryptoJS.AES.encrypt(ele._id, process.env.REACT_APP_PRODUCT_KEY).toString())}`}>
                       
                        <div className="con d-flex flex-column align-items-center">
                            <img src={ele.bimg1} alt="itemimge" />
                            <b>{sliceFiveWords(ele.booktitle)}</b>
                            <div className='d-flex'>

                            <h4 className='text-danger fw-bolder  fs-6 text-decoration-line-through d-flex'><i className="fa-solid fa-indian-rupee-sign"></i>{ele.mrp}</h4>
                            &nbsp; <h4 className='text-primary fw-bolder fs-6 d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{ele.actualprice}</h4>
       &nbsp; <h4 className='text-success fw-bolder fs-6 d-flex '>{ele.discount}% OFF</h4>
      
                            </div>
                        </div>
                        </Link>
                        
                    })}
                    
                    
                    <div className="navigation-btn">
                        <button type="button" className="scroll-btn" id="left-btn"><i className="fa-solid fa-chevron-left"></i></button>
                        
                        <button type="button" className="scroll-btn" id="right-btn"><i className="fa-solid fa-chevron-right"></i></button>
                    </div>
                </div>
        </div>
        </div>
   </section>
   <section className='d-flex justify-content-center mt-3'>
    <img src="/Red Modern and Minimalist Book Store Banner Promotion (40 x 10 in) (40 x 15 in).jpg"  className="banner1" alt="banner"  />
    </section>
   </>
  )
}

export default Offer
