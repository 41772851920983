import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TermCond = () => {
  let location=useLocation()
  useEffect(()=>{
    document.documentElement.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[location])
  return (
    <div class="term-condition">
    <h1>Terms and Conditions</h1>
    <p>
      This document is a written binding agreement and an electronic record under Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes under applicable Indian laws. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published and shall be construed in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 under Information Technology Act, 2000 that require publishing the rules and regulations, privacy policy and user agreement for access or usage of www.Booksindya.in in website.    </p>
    <ul>
      <li>Before accessing this website, please read these Terms & Conditions, Privacy Policy, Report Infringement, Delivery Policy and Return / Replacement Policy carefully. While by accessing this website you are abide to be bound with all the terms & conditions set out below and you agreed to accept all terms and conditions written herein and amended from time to time. </li>
      <li>Booksindya or any of its subsidiary units (i.e. Sub domains) reserves the rights to update the policies or any of its content and compilation of all content in the website from time to time. The content of this website can be used by any individual only for Non-commercial purpose without any Tampering or Editing. Apart from this any other usage like copying, modification, distribution, republication, display or performance of  the content of 'Booksindya.in website’s' without the prior written permission from Booksindya will be strictly prohibited. </li>
    <li>By visiting ‘Booksindya.in websites’ and accepting these Terms of Use, you agree to and abide by these Terms of Use. You also represent and warrant to Booksindya that you will use the website in a manner consistent with any and all applicable laws and regulations. If any of the terms and conditions are unacceptable to You, it is advised to please leave the website.</li>
    
  <li>When you sign up for a Booksindya account and want to avail the products & services that are offered by our website, we safely store all the information that is provided by you. We make use of an order form whereby we provide the opportunity our customers to request for information, products & services. We also collect the contact information (email address & mobile number) of our visitors along with the financial information (credit card & debit card). We use contact information to send orders to the customers along with the information of our company. The information can also be used to communicate with the customers, as and when the need arises. On the other hand, financial information is gathered to invoice customers for different kinds of products and services. </li>
  <li>When You use the Website or send emails or other data, information or communication to the www.Booksindya.in website , You agree and understand that You are communicating with the Website and www.Booksindya.in website and all other Registered Users and Visitors through electronic records which are legally identifiable and enforceable, and You consent to receive communications via electronic records from the Website, www.Booksindya.in website and all other Registered Users and Visitors and as and when posted, communicated or required.</li>
  <li>Service of Notice: The Registration Data and email ID or Your account details of the third party website through which You register with the Website will be construed as Your ‘designated electronic address’ and the www.Booksindya.in Website, other Registered Users, Visitors, third parties and law enforcement agencies will communicate with You on your designated electronic address which will be deemed adequate service of notice / electronic record. By impliedly or expressly accepting this User Agreement, You also accept and agree to be bound by various policies of the www.Booksindya.in  website as provided from time to time in various hyperlinks on the Website.</li>
  

<li>If you use the Website as Registered User, you are responsible for maintaining the confidentiality of your User ID and Password. You are responsible for all activities that occur under your User ID and Password. You agree, inter alia, to provide true, accurate, current and complete information about yourself as prompted by Website registration form or provided by You as a Visitor or user of a third party site through which You access the Website. If you provide any information that is untrue, inappropriate, inaccurate, not current or incomplete o rwww.Booksindya.com has reasonable grounds to suspect that such information is untrue, inaccurate, inappropriate, not current or incomplete, or not in accordance with the User Agreement, www.booksindya has the right to indefinitely suspend or terminate or block access of your membership with the Website and refuse to provide you with access to the Website.</li>
<li>You promise to only use the Services for your personal use and use as allowed by the terms of this www.Booksindya.in website as well as per the terms of the Licence ( “ License”), and only in a manner that complies with all laws that apply to You. If your use of the Services is prohibited by applicable laws, then You aren’t authorized to use the Services. We can’t and won’t be responsible for You using the Services in a way that breaks the law. Any violation of the Terms of Use by anyone using the Services under Your Account (or otherwise under your authority or permission) may be deemed a violation by You, irrespective of whether the violation is with or without Your consent. As already mentioned, your use of the Services is subject to our Acceptable Use Policy, which outlines what You can and can’t do with and on the www.Booksindya.in website You should therefore read the Acceptable Use Policy carefully. Aviolation of the Acceptable Use Policy will be grounds for termination of your right to use or access.</li>
<li>To improve the online customer experience, Booksindya.in makes use of the information collected. Thus, when you sign in, it authenticates you and sends a notification through email. Your request can be fulfilled for different products & services. We will use the information for customizing the advertisements and content according to the customer’s needs. Information used for capturing data insights for internal and external clients.</li>
<li>Booksindya.in restricts access to personal information to the employees, moderators, contractors, and agents for updates, delivery, and for improvement of the product and services. They have to abide by the confidentiality agreement failing which they will be terminated.</li>
<li>We make use of the best security measures for protection against the misuse and loss of information that is under our control. Each customer is important to us and we consider their approval with due respect.</li>


<li>By using Booksindya.in website, you understand and agree that Booksindya.in only provides 3rd Party hosting services to all its "Users".  Booksindya.in website doesn’t manufacture all books which is listed on the sites. All items advertised / listed on the sites are purely based on the cover page of the respective item or based on its content.</li>
<li>'Booksindya.in Website' shall have the right to use Your Information for Marketing, Promotional Purpose, E-mails, Calls, or by any other mode of communication to deliver the Latest or Upcoming offers, Sales, Latest Products. With respect to Your Information Booksindya.in website will only use Your Information in accordance with the User Agreement and Privacy Policy.</li>
<li>In the event that any unethical transactions or attempted transaction relating to any item, goods or services or any issues which may breaches the User Agreement or applicable law comes to your knowledge, You are liable to take all necessary steps to contact Booksindya.in website legal head through written E-mails at <span>booksindya2024@gmail.com</span></li>
<li>'Booksindya.in Website’s' may have variable price in our group sites. Generally we used multiple Payment Gateway to receive payment from buyers. So, No questions should be asked ! Before purchasing please go through it properly.</li>

<li>Booksindya does not make any representation or warranty as to specifics (such as quality, value, stability, warranty etc) of the products or services proposed to be sold or offered to be sold or purchased on the website.</li>
<li>Our Services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us on <span> booksindya2024@gmail.com </span> or <span>(+91) 7003810616</span> so that we will be able to do the necessary actions. </li>
<li>Whenever purchasing any product from Booksindya.in website  valid address, PIN Code, Phone No., E-mail ID must be mentioned. If delivery could not be made possible due to wrong or incomplete address or PIN Code, Phone No., we shall hold the order and sort out the same within next 30 working days. But no refund shall be made for such mistakes made by the buyer. A partial refund may be made by Booksindya, if considered necessary, after deducting the courier charges & other requisite charges.</li>
<li>We deliver products through renowned third party courier services. Such delivery charge for each product is mentioned on the website. If the product is not delivered within  expected period of delivery, buyer may himself inquire into the respective courier company and their respective official addresses or can contact our sales department directly but no refund will be initiated for delay in delivery by the respective courier partners. For more information about Shipping Fees & Delivery Policy, Vesit Shipping & Delivery polices.</li>
<li>Before shipping / delivering your order to you, Booksindya or our Logistic Partners may request you to provide supporting documents (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment instrument used by you for your purchase. This is done in the interest of providing a safe online shopping environment to our users;</li>
<li>Booksindya use third-Party Payment Gateway. Therefore, we don’t save users bank details. If in case, we upgrade the site, then we might be saving user’s data like A/C No. & A/C Name & IFSC Code only. But no one of our team will ask you your OTP, Other Security Data, any sensitive financial information, neither we have any interest to save such type of high security data. If you suspect any fraudulent activities in the name of Booksindya, you can directly write an E-mail to <span> booksindya2024@gmail.com </span> subject to "SUSPECT FRAUDULENT ACTIVITIES" with the detail description including your Name, E-mail ID & Contact details. </li>
<li>These Terms constitute the entire agreement between the Owner and you in relation to your use of this Website and supersede all prior agreements and understandings.</li>
<li>Booksindya.in Website may sell books of other Publications. If any other Publication house has any objection, they can directly contact us through e-mails; We don’t have any intention to violate your intellectual property rights. </li>
<li>The pricing of books listed on Booksindya.in Website are for general B2C (Business to Customers) and it may vary depends on sales channels. "Users" are requested to go through the pricings very carefully before placing any orders. After placing any orders no excuse will be entertained. If any order cancel request is placed by "Users" for this pricing variations or changes, That requests will not be accepted by Booksindya Officials. and "Users" will not get any refund, replacement or exchange due to market price fluctuations.The offers and discounts (if available) will be clearly visible on Booksindya.in websites and there will be no additional discount and offers provided to B2C (Business to Customers).</li>

<li>Booksindya will not Sell your personal data to any 3rd parties for their marketing or any other purposes without your explicit consent, we only use your information as described in the Privacy Policy. User’s privacy protection is in our top priority. We understand clearly that You and Your Personal Information is one of our most valuable assets. We store and process Your Information on Cloud Based Hosting Server which is purely safe & secured.</li>
<li>   Booksindya.in Website Promises all users to deliver the genuine products of all Brands or Regional Products, However, If you suspect any pirated copies are sold out by any local / online market place websites & using our brands, users can immediately E-mail us at <span> "Booksindya2024@gmail.com" </span>with proper details. So, our legal departments can investigate the case at the earliest. 
  <br/><span>Pricing:</span>Booksindya.in Website listed all products with the actual M.R.P of the products (As per the latest edition). While adding any item to cart users can check properly the following points i.e. MRP, SP (Selling Price), Discount which may highlight clearly on that respective product page.
  <br/><span>Refund:</span>Booksindya.in Website  team is (24x7) ready to work on users query. After validation of each case, we forward the case to our fulfillment centre. If our fulfillment centre found any single mistakes from our end in such cases only we will be liable to  "Refund"  full amount without any hesitation as per the procedure. For any type of Product Damages during transmission by the Logistic Couriers generally "Replacement Guarantee" will be preferred without any extra charges. For more details, please visit "Return policy".
 <br/><span> Secure Payments:</span> Booksindya.in Users' Personal Data is kept Confidential with us & with 3rd Party Payment Gateway platform i.e. Google pay & Phone pay, Bhim App etc. All Online Transactions are 100% safe & secured by SSL (Secure Socket Layer). Infact, we won't interetsed to leak users data to any 3rd Party at any cost.</li>
<li> These Terms are governed by and will be construed under the laws of the Republic of India, without regard to the conflicts of laws provisions there of. For all purposes of these Terms (including other terms and policies referred herein), the parties consent to exclusive jurisdiction and venue in the courts located in city of Dhanbad, state of Jharkhand, Republic of India and each of the parties hereby irrevocably consents to the jurisdiction of such courts (and of the appropriate appellate courts there from) in any such suit, action or proceeding and irrevocably waives, to the fullest extent permitted by law, any objection that it may now or hereafter have to the laying of the venue of any such suit, action or proceeding in any such court or that any such suit, action or proceeding brought in any such court has been brought in an inconvenient forum. Process in any such suit, action or proceeding may be served on any party anywhere in the world, whether within or without the jurisdiction of any such court.
</li>


</ul>
    

  </div>
  )
}

export default TermCond