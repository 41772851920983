import React, { useEffect, useState } from 'react'


const Crousal = () => {

  return (
<section className='d-flex justify-content-center'>
<div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">


   <div className="carousel-item active">
<img src="/Untitled1.jpeg" className="d-block w-100" alt="..."/>
</div>

<div className="carousel-item">
<img src="/Untitled.jpeg" className="d-block w-100" alt="..."/>
</div>
    

<div className="carousel-item">
<img src="/Orange Modern and Simple Book Store Banner (40 x 20 in).jpg" className="d-block w-100" alt="..."/>
</div>

<div className="carousel-item">
<img src="/Digital Marketing LinkedIn Banner  (40 x 20 in).jpg" className="d-block w-100" alt="..."/>
</div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

</section>
  )
}

export default Crousal
