import React, { useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import  { useState } from 'react'

import { useContext } from "react";
import Context from "./context/Searchcontext";
import Navcollapes from "./Minorcomponents/Navcollapes";


const Navbar = () => {
  
  let location = useLocation();
  const {alert,search,setsearch,setalert,page, setPage,items, setItems,hasMore,length, setlength, setHasMore}=useContext(Context)

let context=useContext(Context)


  useEffect(()=>{
if( location.pathname==="/reqbook"  || location.pathname==="/createorder" || location.pathname==="/billing" ||  location.pathname==="/contactus" ||  location.pathname==="/login" ||  location.pathname==="/signup" || location.pathname==="/forget" ){
  document.querySelector(".footer").style.display="none"
  document.querySelector("header").style.display="none"
}

else if(location.pathname==="/search" || location.pathname==="/product/:id" || location.pathname==="cart" || location.pathname==="/createorder" || location.pathname==="/billing" || location.pathname==="/myorder" || location.pathname==="/orderhistory" || location.pathname==="/mypolicy"  || location.pathname==="/newarrival"  || location.pathname==="/toppublisher"  || location.pathname==="/bestseller"  || location.pathname==="/used&old" || location.pathname==="/combo&offer"){
  document.querySelector(".footer").style.display="none"
}

else{
  document.querySelector(".footer").style.display="flex"
  document.querySelector("header").style.display="block"
}

  },[location])
const navigate=useNavigate()

const [count, setcount] = useState(0)
  const [progress, setProgress] = useState(0)
const [indicate, setindicate] = useState(false)


  
useEffect(()=>{
setTimeout(()=>{
setProgress(100)
},300)
},[progress])






  const handleclick=()=>{
 localStorage.clear()

  }



  
  useEffect(()=>{
    if(localStorage.getItem("token")){
      
      fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/countdoc`,{
        method:"POST",
        headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
      }
    }).then((res)=>res.json()).then((ele)=>setcount(ele))
  }
})





useEffect(()=>{
window.addEventListener("resize",()=>{



  if(window.innerWidth<=1000){
    document.getElementById("right-nav2").style.display="none"


   
  }
  else{
    if(indicate){
      document.getElementById("right-nav2").style.display="none"
      document.getElementById("all-cat").style.display="none"
    }
    else{

      document.getElementById("right-nav2").style.display="flex"
      document.getElementById("all-cat").style.display="flex"
    }

  }
})
},[])
const changeindi=()=>{

  if(window.innerWidth>=1000){
    if(document.getElementById("searchform").style.display==="block"){
      document.getElementById("searchform").style.display="none"
      document.getElementById("right-nav2").style.display="flex"
      document.getElementById("all-cat").style.display="flex"

      
      setindicate(false)
    }
    else{
      document.getElementById("right-nav2").style.display="none"
      document.getElementById("all-cat").style.display="none"
      document.getElementById("searchform").style.display="block"

      setindicate(true)
      
    }
  }

  else{
    if(document.getElementById("searchform").style.display==="block"){
      document.getElementById("searchform").style.display="none"
  
      document.getElementById("all-cat").style.display="flex"

     
      setindicate(false)
    }
    else{
      document.getElementById("right-nav2").style.display="none"
      document.getElementById("all-cat").style.display="none"
      document.getElementById("searchform").style.display="block"
      setindicate(true)

    }
  }

}
let typingTimer;
const doneTypingInterval = 1000;

const handlesearch=(e)=>{
  e.preventDefault()
navigate("/search")
  setItems([])
  setHasMore(true)
  clearTimeout(typingTimer);
  typingTimer = setTimeout(()=>{ 
    setsearch(e.target.value)
    setPage(0)

  }, doneTypingInterval);
}


const changesearch=(e)=>{
  e.preventDefault()
  setsearch(search)
}

  return (
    <>

    <div className="top_navbar">

    <Navcollapes/>
<header >
  <nav >
    <div className="navcontainer">
      <Link to="/">
      <img src="/logo.png"  />
      </Link>
      
      <div className="social">
      <Link to="https://www.facebook.com/profile.php?id=61553364841273&mibextid=ZbWKwL"><i class="fa-brands fa-facebook"></i></Link>
      <Link to="https://www.instagram.com/booksindya.in?igsh=YnI3MG1zZ29jN2sz"><i class="fa-brands fa-instagram"></i></Link>
      <Link to="https://play.google.com/store/apps/details?id=com.grabs4buisness.booksindya"><i class="fa fa-android"></i></Link>
      <Link to="https://www.youtube.com/@Booksindya"><i class="fa-brands fa-youtube"></i></Link>
      <Link to="https://wa.me/917003810616?text=Hello Booksindya"><i class="fa-brands fa-whatsapp"></i></Link>
     <div className="address">
      <Link><i class="fa-solid fa-envelope"></i> Booksindya2024@gmail.com</Link>
      <Link to="tel:+917003810616"><i class="fa-solid fa-phone"></i> (+91) 7003810616</Link>
      <Link><i class="fa-solid fa-earth-asia"></i> INDIA</Link>
     </div>

      </div>
      <div className="cart">

      <Link to='/wishlist' className="cart-link"><i class="fa-solid fa-bag-shopping"></i></Link>
      <Link to='/cart' className="cart-link"><i class="fa-solid fa-cart-shopping"></i> {count.count && <span><sup>{count.count}</sup></span>} </Link>
      </div>
    </div>
  </nav>


  <div className="navcontainer2">

    <Link  id="all-cat" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="staticBackdrop" className="nav2-fc"><i class="fa-sharp fa-solid fa-bars fa-lg"></i> <b > ALL CATEGORIES</b></Link>

<div className="right-nav2" id="right-nav2">

<Link to="/toppublisher">TOP PUBLISHERS</Link>
<Link to="/bestseller">BESTSELLER BOOKS</Link>
<Link to="/combo&offer">COMBO/OFFERS BOOKS</Link>
<Link to="/used&old">USED/OLD BOOKS</Link>
</div>



<form id="searchform" onSubmit={changesearch} >

<input type="search" name="search" onChange={handlesearch} id="inputField" placeholder="Search - Title / Publication / ISBN / Author" />

</form>
<div className="nav2-cross">
  {

    !indicate? <Link onClick={changeindi}><i class="text-light fa-solid fa-magnifying-glass"></i></Link>:
  <Link onClick={changeindi}><i class=" text-light fa-solid fa-circle-xmark"></i></Link>
}
</div>

  </div>

  <div className="navcontainer3">
  <Link to="/cart" className="cart-3"><i class="fa-solid fa-cart-shopping"></i> <span>Cart</span></Link>
  <Link to="/wishlist" className="cart-3"><i class="fa-solid fa-bag-shopping"></i> <span>Wishlist</span></Link>
<div className="navchild-3">

  <button type="button"><i class="fa-solid fa-truck-fast"></i> <span> Order</span></button>

  <div className="drop-down">
  <Link to="/myorder"><i class="fa-solid fa-truck"></i> My Order</Link>
  <Link to="/orderhistory"><i class="fa-solid fa-clock-rotate-left"></i> Order History</Link>

  <Link to="https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx"><i class="fa-solid fa-truck-fast"></i> Track Your Order</Link>

  </div>
</div>



<div className="navchild-3">

  <button type="button"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
<path d="M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2H13.5C17.2712 2 19.1569 2 20.3284 3.17157C21.5 4.34315 21.5 6.22876 21.5 10V14C21.5 17.7712 21.5 19.6569 20.3284 20.8284C19.1569 22 17.2712 22 13.5 22H12C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10Z" stroke="#000000" stroke-width="3"/>
<path d="M9.79993 11.9741C9.37332 11.2302 9.16733 10.6228 9.04313 10.007C8.85943 9.09641 9.27981 8.20686 9.97622 7.63926C10.2706 7.39937 10.608 7.48133 10.782 7.79358L11.1749 8.49851C11.4864 9.05725 11.6421 9.33663 11.6112 9.63282C11.5803 9.929 11.3703 10.1702 10.9503 10.6527L9.79993 11.9741ZM9.79993 11.9741C10.6634 13.4797 12.0185 14.8356 13.5259 15.7001M13.5259 15.7001C14.2698 16.1267 14.8772 16.3327 15.493 16.4569C16.4036 16.6406 17.2931 16.2202 17.8607 15.5238C18.1006 15.2294 18.0187 14.892 17.7064 14.718L17.0015 14.3251C16.4427 14.0136 16.1634 13.8579 15.8672 13.8888C15.571 13.9197 15.3298 14.1297 14.8473 14.5497L13.5259 15.7001Z" stroke="#000000" stroke-width="3" stroke-linejoin="round"/>
<path d="M5 6L2.5 6M5 12L2.5 12M5 18H2.5" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg><span> Contact Us</span></button>

  <div className="drop-down">
  <Link to="/contactus"><i class="fa-solid fa-at"></i> Quick Support</Link>
  <Link to="/reqbook"><i class="fa-solid fa-hands-praying"></i> Request For Books</Link>


  </div>
</div>





<div className="navchild-3">

  <button type="button"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
<circle cx="12" cy="12" r="10" stroke="#000000" stroke-width="3"/>
<path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="#000000" stroke-width="3" stroke-linecap="round"/>
</svg><span>{localStorage.getItem("name")?localStorage.getItem("name"):"Login"} </span></button>

  <div className="drop-down">
    {
      
      !localStorage.getItem("token")? <Link to="/login"><i class="fa-solid fa-right-to-bracket"></i> Login</Link>
  :<Link to="#" onClick={handleclick}><i class="fa-solid fa-right-to-bracket fa-rotate-180"></i> Logout</Link>
    }

  </div>
</div>








  </div>
</header>


      </div>


    </>
  );
};

export default Navbar;
