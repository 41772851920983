import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../context/Searchcontext'
import { useContext } from 'react'
const Cardcart = (props) => {
  const context=useContext(Context)
  const {alert,change,setchange}=context
  const [proalert, setproalert] = useState(null)
    const [add, setadd] = useState(0)
    const navigate=useNavigate()
const [item, setitem] = useState([])

    
useEffect(()=>{
  
  fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getitem`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },body:JSON.stringify({_id:props.ele.bookid})
  }).then((res)=>res.json()).then((ele)=>{
  
    if(ele.success==false)
    {
      navigate("*")
    }
    setitem(ele)
  })
  },[])
    const desclick=async(e)=>{
   e.preventDefault()
   localStorage.getItem("token") &&   fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/addtocart`,{
          method:"POST",
          headers:{
            "Content-Type":"application/json",
            "token":localStorage.getItem("token")
          },body:JSON.stringify({id:props.ele.bookid,booktitle:props.ele.booktitle,imgtext1:props.ele.bimg1,mrp:props.ele.mrp,discount:props.ele.discount,binding:props.ele.binding,publication:props.ele.publication,actualprice:props.ele.actualprice,quantity:props.ele.quantity-1,mode:props.ele.mode})
        }).then((res)=>res.json()).then((ele)=>{
          setchange(change-1)
        })
      
    }
    const inclick=async(e)=>{
      e.preventDefault()
   
      localStorage.getItem("token") &&   fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/addtocart`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "token":localStorage.getItem("token")
        },body:JSON.stringify({id:props.ele.bookid,booktitle:props.ele.booktitle,imgtext1:props.ele.bimg1,mrp:props.ele.mrp,discount:props.ele.discount,binding:props.ele.binding,publication:props.ele.publication,actualprice:props.ele.actualprice,quantity:props.ele.quantity+1,mode:props.ele.mode})
      }).then((res)=>res.json()).then((ele)=>{
        
        setchange(change+1)
      })
    }

const deleteproduct=async(e)=>{
 
    e.preventDefault()
    fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/deletefromcart`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        "token":localStorage.getItem("token")
      },body:JSON.stringify({id:props.ele.bookid})
    }).then((res)=>res.json()).then((ele)=>{
      
    if(ele.success)
    {
  window.location.reload()
    }
  })

}



  return (
    <>
    {proalert && <div className={`alert alert-${proalert.color} alert-dismissible fade show text-center position-sticky bottom-50`}  role="alert">
  <strong><i className="fa-regular fa-thumbs-up"></i></strong> {proalert.message}
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}

     <div className="cart-card d-flex gap-3">

<img src={props.ele.imgtext1} alt="productimage"  />
<div className="content w-100 d-flex flex-column justify-content-around">
    <Link to={`/productpage/${props.ele.bookid}`}>
<h4 className=' fw-bolder'>{props.ele.booktitle}</h4>
    </Link>
<h5 className='text-dark fw-bolder'><i className="fa-solid fa-indian-rupee-sign"></i>{props.ele.mrp*props.ele.quantity} - <span className='text-danger'>({props.ele.discount}% discount)</span> = <span className='text-success'><i className="fa-solid fa-indian-rupee-sign"></i>{(props.ele.actualprice*props.ele.quantity).toFixed(2)}</span></h5>
<div >

<button className={`btn btn-secondary  ${props.ele.quantity==1? "disabled":""}` } onClick={desclick}><i className="fa-solid fa-minus"></i></button>
<input type="text" id="add-to-cart" style={{width:"50px",padding:"5px",borderRadius:"5px",border:"none"}} disabled value={props.ele.quantity} />
    <button className={`btn btn-secondary  ${props.ele.quantity==item.stock? "disabled":""}`}onClick={inclick} ><i className="fa-solid fa-plus"></i></button>
</div>
<div>
<div className='bandeges'>

<span className="badge text-bg-danger">{props.ele.binding}</span>
<span className="badge text-bg-info mx-2">{props.ele.publication}</span>
</div>
<div className='continue'>

<button type='button' className='btn btn-secondary  btn-sm mx-2 text-nowrap' onClick={deleteproduct}><i className="fa fa-trash" aria-hidden="true"></i><span> Remove</span></button>

</div>
</div>



</div>
        </div>
    </>
  )
}

export default Cardcart